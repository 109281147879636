import React from "react";
import { useState } from "react";
import useDistanceRequests from "../hooks/distance-request";

import '../css/patient-group-modal.css'

const PatientGroupModal = ({accessToken, userId, modalProps, closeModal}) => {

  const { saveGroupSet } = useDistanceRequests();

  const [groupSetToSend, setGroupSetToSend] = useState(undefined);
  const [eventSelected, setEventSelected] = useState(undefined);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    const weekday = weekdays[date.getDay()];
    const month = date.getMonth() + 1; // getMonth() returns 0-11
    const day = date.getDate();
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12; // hour '0' should be '12'
    const minutesPadded = minutes < 10 ? '0' + minutes : minutes;

    return (
      <>
          {weekday}<br />
          {month}/{day}/{year}<br />
          {hours}:{minutesPadded}{ampm}
      </>
    );
  };

  const handleAssignEvent = (event) => {
    if(modalProps.groups) {
      const newGroupSet = {...modalProps.groups}
      const groupToChange = [...newGroupSet.groups[modalProps.groupNum].pats];
      const patientToChange = {...modalProps.patient};
      patientToChange.manualScheduled = event.id;
      groupToChange[modalProps.patientIndex] = patientToChange;
      newGroupSet.groups[modalProps.groupNum].pats = groupToChange;
      setGroupSetToSend(newGroupSet);
      setEventSelected(event.start);
    }
  }

  const confirmSelection = () => {
    saveGroupSet(userId, accessToken, groupSetToSend);
    closeModal();
  }

  return (
    <div className="container patient-modal">
      <div className="row">
        <div className="col py-2 p-modal-title-cont">
          <div className="p-modal-title">Assign an Event to This Patient</div>
          <button onClick={closeModal} type="button" className="btn-close close-all close-p-notes me-1" aria-label="Close"></button>
        </div>
      </div>

      <div className="row my-4">
        <div className="col">
          <div className="d-flex align-items-center justify-content-start pat-name-modal">
            {modalProps.patient.firstName} <span className="ms-1">{modalProps.patient.lastName}:</span> <span className="ms-1">Group {modalProps.groupNum + 1}</span>
          </div>
        </div>
      </div>

      <div className="row">
        {
          modalProps.patient ? 
            (
              <div>
                <div className="row my-3">
                  <div className="col-4">
                    <div className="p-modal-label">Primary Number:</div>
                  </div>
                  <div className="col">
                    <div className="p-modal-label pat-modal-info">{modalProps.patient.primaryNumber || 'NA'}</div>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-4">
                    <div className="p-modal-label">Frequency:</div>
                  </div>
                  <div className="col">
                    <div className="p-modal-label pat-modal-info">{modalProps.patient.frequency}/week</div>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-4">
                    <div className="p-modal-label">Address:</div>
                  </div>
                  <div className="col">
                    <div className="p-modal-label pat-modal-info">{modalProps.patient.address}</div>
                  </div>
                </div>
              </div>
              
            ):null
        }
      </div>

      <div className="row my-4">
        {
          modalProps.availableEvents && modalProps.availableEvents.length > 0 ?
          (
            modalProps.availableEvents.map((event) => (
              <div key={event.id} className="col-4">
                <button className={`btn event-choose ${eventSelected === event.start ? 'event-choose-selected' : null}`} onClick={() => handleAssignEvent(event)}>
                  {formatDate(event.start)}
                </button>
              </div>
            ))
          ) : null
          
        } 
        {
          modalProps.availableEvents && modalProps.availableEvents.length > 0 ?
          (
            <div className="row">
              <button onClick={confirmSelection} className="sa-btn" disabled={!groupSetToSend}>Confirm</button>
            </div> 
          ) : null
        }
      </div>
    </div>
  )
}

export default PatientGroupModal;