import React, { createContext, useState, useContext } from 'react';

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({ show: false, header: '', message: '', variant: '' });

  const showAlert = (message, header, variant) => {
    setAlert({ show: true, header, message, variant });
    setTimeout(() => {
      hideAlert()
    }, 4000);
  };

  const hideAlert = () => {
    setAlert({ show: false,  header: '', message: '', variant: '' });
  };

  return (
    <AlertContext.Provider value={{ alert, showAlert, hideAlert }}>
      {children}
    </AlertContext.Provider>
  );
};
