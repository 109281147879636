import React from "react";
import { useState, useEffect, useContext } from "react";

import LogoutButton from "../auth/LogoutButton";

import { AddressAutofill, useConfirmAddress  } from '@mapbox/search-js-react';
import useUserRequests from "../hooks/user-requests";

const Onboarding = ({authId, accessToken}) => {
  const maps_access_Token = process.env.REACT_APP_MAPS_API_KEY;
  const { formRef, showConfirm } = useConfirmAddress({
    footer: 'My custom footer',
    accessToken: maps_access_Token,
  });
  const { addUser } = useUserRequests();

  const [userData, setUserData] = useState({
    authId: authId,
    role: ['USER'],
    email: '',
    primaryPhone: '',
    addressObject: {
      addressLine: '',
      apartment: '',
      city: '',
      state: '',
      postcode: '',
      country: '',
    },
    coordinates: '',
    address: '',
    firstName: '',
    lastName: '',
    designation: '',
    sessionLength: 50,  
    bufferTime: 5,
    maxVisits: 6,  
    workingDays: 5,  
    startTime: '',
    lastLogin: '',  
  }); 

  useEffect(() => {
    const now = new Date();
    setUserData(prev => ({
      ...prev,
      lastLogin: now
    }))
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData(prev => ({
        ...prev,
        [name]: value
    }));
  }; 

  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    if(name === ' address-search') {
      setUserData(prev => ({
        ...prev,
        addressObject: {
            ...prev.addressObject,
            addressLine: value
        }
      }));
    } else {
        setUserData(prev => ({
        ...prev,
        addressObject: {
            ...prev.addressObject,
            [name]: value
        }
      }));
    }
  };

  const handleSaveUser = async () => {
    const result = await showConfirm();

    try {
      const response = await addUser(userData, accessToken);

      if(response.error) {
        if(response.status === 400) {
          console.log('bad req');
        } else {
          console.log(`An Unexpected Error Occured: ${error.message}`);
        }
      } else {
        window.location.pathname = '/';
      }
      
    } catch (error) {
        console.log(`An Unexpected Error Occured: ${error.message}`);
    }
    
  };

  return (
    <div>
  
      <label htmlFor="email">Email:</label>
      <input type="email" name="email" value={userData.email} onChange={handleChange} placeholder="Email" />

      <label htmlFor="primaryPhone">Primary Phone:</label>
      <input type="tel" name="primaryPhone" value={userData.primaryPhone} onChange={handleChange} placeholder="Primary Phone" />

      <form 
        ref={formRef}
        style={{ display: 'flex', flexDirection: 'column', marginTop: 30 }}
      >
        <AddressAutofill accessToken={'pk.eyJ1IjoiY29ubm9yY29va2RldiIsImEiOiJjbHAxdWpzZmowbDVwMmxwNWk3cTcyenF0In0.kJiFCWKK_yzkZOK7amzd0g'}>
          <input
              placeholder="Address" type="text"
              autoComplete="street-address"
              onChange={handleAddressChange}
          />
        </AddressAutofill>
        <input
            name="apartment" placeholder="Apartment number" type="text"
            autoComplete="address-line2"
            onChange={handleAddressChange}
        />
        <input
            name="city" placeholder="City" type="text"
            autoComplete="address-level2"
            onChange={handleAddressChange}
        />
        <input
            name="state" placeholder="State" type="text"
            autoComplete="address-level1"
            onChange={handleAddressChange}
        />
        <input
            name="country" placeholder="Country" type="text"
            autoComplete="country"
            onChange={handleAddressChange}
        />
        <input
            name="postcode" placeholder="Postcode" type="text"
            autoComplete="postal-code"
            onChange={handleAddressChange}
        />
      </form>

      <label htmlFor="firstName">First Name:</label>
      <input type="text" name="firstName" value={userData.firstName} onChange={handleChange} placeholder="First Name" />

      <label htmlFor="lastName">Last Name:</label>
      <input type="text" name="lastName" value={userData.lastName} onChange={handleChange} placeholder="Last Name" />

      <label htmlFor="designation">Designation:</label>
      <input type="text" name="designation" value={userData.designation} onChange={handleChange} placeholder="Designation" />

      <label htmlFor="sessionLength">Session Length (in minutes):</label>
      <input type="number" name="sessionLength" value={userData.sessionLength} onChange={handleChange} placeholder="Session Length" />

      <label htmlFor="bufferTime">Buffer Time:</label>
      <input type="text" name="bufferTime" value={userData.bufferTime} onChange={handleChange} placeholder="Buffer Time" />

      <label htmlFor="maxVisits">Max Visits:</label>
      <input type="number" name="maxVisits" value={userData.maxVisits} onChange={handleChange} placeholder="Max Visits" />

      <label htmlFor="workingDays">Working Days:</label>
      <input type="number" name="workingDays" value={userData.workingDays} onChange={handleChange} placeholder="Working Days" />

      <label htmlFor="startTime">Start Time:</label>
      <input type="time" name="startTime" value={userData.startTime} onChange={handleChange} placeholder="Start Time" />
    

    <div>
       <button onClick={handleSaveUser}>Submit</button>
    </div>
    <div>
      <LogoutButton />
    </div>
    </div>
    
  )

};

export default Onboarding;