import { useState } from 'react';
import usePatientRequests from '../hooks/patient-requests';
import { useAlert } from '../context/alert-context';
import { AddressAutofill, useConfirmAddress  } from '@mapbox/search-js-react';

import Loading from '../pop-ups/loading';

import { Form, Badge, CloseButton, Button, InputGroup, Row, Col, Container, Stack } from 'react-bootstrap';
import styles from "./Modal_Components.module.css";

function CreatePatient({userId, accessToken, handleClose}) {
  const maps_access_Token = process.env.REACT_APP_MAPS_API_KEY;
  const { addNewPatient } = usePatientRequests();
  const { formRef, showConfirm } = useConfirmAddress({
    footer: 'My custom footer',
    accessToken: maps_access_Token,
  });
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(
    {
      firstName: '',
      lastName: '',
      addressObject: {
        addressLine: '',
        apartment: '',
        city: '',
        state: '',
        postcode: '',
        country: '',
      },
      coordinates: '',
      address: '',
      primaryNumber: '',
      secondaryNumber: '',
      email: '',
      noSeeDays: {sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false},
      active: true,
      frequency: 1,
      notes: []
    }
  );

  const handleInputChange = (event) => {
    const {name, value} = event.target;

    setFormData({
      ...formData,
      [name]: value
    });
  }

  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    if(name === ' address-search') {
      setFormData(prevFormData => ({
        ...prevFormData,
        addressObject: {
            ...prevFormData.addressObject,
            addressLine: value
        }
      }));
    } else {
        setFormData(prevFormData => ({
        ...prevFormData,
        addressObject: {
            ...prevFormData.addressObject,
            [name]: value
        }
      }));
    }
    
  }

  const handleNoSeeDays = (value) => {
    setFormData((prev) => ({
      ...prev,
      noSeeDays: {
        ...prev.noSeeDays,
        [value]: !prev.noSeeDays[value]
      }
    }));
  }

  const checkForAddress = () => {
    let isAddress;
    for (const key in formData.addressObject) {
      if (Object.hasOwnProperty.call(formData.addressObject, key)) {
        const element = formData.addressObject[key];

        if(element === '' && key !== 'apartment') {
          isAddress = false;
          break;
        } else {
          isAddress = true;
        }
      }
    }
    return isAddress;
  }

  const handleSave = async () => {
    await showConfirm();
    setLoading(true);
    const response = await addNewPatient(formData, userId, accessToken);

    if(response.status === 201) {
      showAlert('Saved! Wow that was hard work. Go get a treat to celebrate!', 'Success',  'success');
      setTimeout(() => {
        handleClose();
      }, 800);
    } else {
      showAlert(`ERROR: ${response.message}`, 'Oh, Snap!', 'danger');
      setLoading(false);
    }
  }

  if(!loading) {
    return (
      <Container>
        <Row>
          <Col md={6}>
            <Form.Label className='SA-label'>First Name</Form.Label>
            <Form.Control
              type="text"
              className="SA-form-input my-2"
              placeholder='e.g. Jane'
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={(event) => handleInputChange(event)}
            />
          </Col>
          <Col md={6}>
            <Form.Label className='SA-label'>Last Name</Form.Label>
            <Form.Control
              type="text"
              className="SA-form-input my-2"
              placeholder='e.g. Doe'
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={(event) => handleInputChange(event)}
            />
          </Col>
        </Row>
        <Row className='py-3'>
          <Col>
            <Form.Label className='SA-label'>Address</Form.Label>
            <Form ref={formRef}>
              <Row>
                <Col md={9}>
                  <AddressAutofill accessToken={maps_access_Token}>
                    <Form.Control
                        type="text"
                        className="SA-form-input my-2"
                        placeholder="Address" 
                        autoComplete="address-line1"
                        onChange={handleAddressChange}
                    />
                  </AddressAutofill>
                </Col>
                <Col md={3}>
                  <Form.Control
                    type="text"
                    className="SA-form-input my-2"
                    name="apartment" placeholder="Apt"
                    autoComplete="address-line2"
                    onChange={handleAddressChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Control
                    type="text"
                    className="SA-form-input my-2"
                    name="city" placeholder="City"
                    autoComplete="address-level2"
                    onChange={handleAddressChange}
                  />
                </Col>
                <Col md={6}>
                  <Form.Control
                    type="text"
                    className="SA-form-input my-2"
                    name="state" placeholder="State"
                    autoComplete="address-level1"
                    onChange={handleAddressChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Control
                    type="text"
                    className="SA-form-input my-2"
                    name="country" placeholder="Country" 
                    autoComplete="country"
                    onChange={handleAddressChange}
                  />
                </Col>
                <Col md={6}>
                  <Form.Control
                    type="text"
                    className="SA-form-input my-2"
                    name="postcode" placeholder="Postcode"
                    autoComplete="postal-code"
                    onChange={handleAddressChange}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row className='py-3'>
          <Col md={6}>
            <Form.Label className='SA-label'>Primary Number</Form.Label>
            <Form.Control
              type="tel"
              className="SA-form-input my-2"
              placeholder='e.g. 480-123-456'
              id="primaryNumber"
              name="primaryNumber"
              value={formData.primaryNumber}
              onChange={(event) => handleInputChange(event)}
            />
          </Col>
          <Col md={6}>
            <Form.Label className='SA-label'>Secondary Number</Form.Label>
            <Form.Control
              type="tel"
              className="SA-form-input my-2"
              placeholder='e.g. 480-123-456'
              id="secondaryNumber"
              name="secondaryNumber"
              value={formData.secondaryNumber}
              onChange={(event) => handleInputChange(event)}
            />
          </Col>
        </Row>
        <Row className='py-3'>
          <Col>
            <Form.Label className='SA-label'>Email</Form.Label>
            <Form.Control
              className="SA-form-input my-2"
              placeholder='e.g. janedoe@example.com'
              id="email"
              name="email"
              value={formData.email}
              onChange={(event) => handleInputChange(event)}
            />
          </Col>
        </Row>
        <Row className='py-3'>
          <Col md={4}>
            <Form.Label className='SA-label'>Cannot Be Seen:</Form.Label>
            <Form.Select className="SA-form-input" name="noseeDays" id="noSeeDays" onChange={(event) => handleNoSeeDays(event.target.value)}>
              <option disabled defaultValue={"Select Days"} value="">Select Days</option>
              <option className={`${formData.noSeeDays.sunday ? 'd-none' : null}`} value="sunday">
                Sunday
              </option>
              <option className={`${formData.noSeeDays.monday ? 'd-none' : null}`} value="monday">
                Monday
              </option>
              <option className={`${formData.noSeeDays.tuesday ? 'd-none' : null}`} value="tuesday">
                Tuesday
              </option>
              <option className={`${formData.noSeeDays.wednesday ? 'd-none' : null}`} value="wednesday">
                Wednesday
              </option>
              <option className={`${formData.noSeeDays.thursday ? 'd-none' : null}`} value="thursday">
                Thursday
              </option>
              <option className={`${formData.noSeeDays.friday ? 'd-none' : null}`} value="friday">
                Friday
              </option>
              <option className={`${formData.noSeeDays.saturday ? 'd-none' : null}`} value="saturday">
                Saturday
              </option>
            </Form.Select>
          </Col>
          <Col md={8}>
            <Row>
              {
                Object.entries(formData.noSeeDays).map(([propertyName, propertyValue]) => (
                  propertyValue && (
                  <Col className='m-2' key={propertyName}>
                    <Badge className={`${styles.customBadge} d-flex justify-content-start align-items-center`} id={styles.customBadge}>
                      <CloseButton className={`${styles.xBtn} me-2`} onClick={() => handleNoSeeDays(propertyName)} /> 
                      {propertyName}
                    </Badge>
                  </Col>
                )))
              }
            </Row>
          </Col> 
        </Row>
        <Row className='py-4'>
          <Col className='d-flex justify-content-center align-items-center'>
            <Button disabled={checkForAddress() === false || formData.firstName === '' || formData.lastName === ''} onClick={handleSave} className="SA-Primary-Btn">Save</Button>
          </Col>
        </Row>
      </Container>
    );
  }

  if(loading) {
    return (
      <Container fluid className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <Loading />
          </Col>
        </Row>
      </Container>
    );
  }
  
}

export default CreatePatient;