import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ScrollAnimationSingle = ({component, time}) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div data-aos="fade-up">
      {component}
    </div>
  );
};

export default ScrollAnimationSingle;




