import React from 'react';
import { useLocation, Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import styles from './Nav.module.css'; 

const AppNavbar = () => {
  const location = useLocation();

  if (location.pathname === '/register') {
    return null;
  }

  return (
    <Navbar collapseOnSelect expand="lg" className={`${styles.navbar} px-2`}>
        <Navbar.Brand className={styles.custNavbarBrand} as={Link} to="/">
          MySchedulePal
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link 
              as={Link} 
              to="/" 
              className={`${styles.navLink} ${location.pathname === "/" ? styles.navLinkActive : ""}`}
            >
              Dashboard
            </Nav.Link>
            <Nav.Link 
              as={Link} 
              to="/manage" 
              className={`${styles.navLink} ${location.pathname === "/manage" ? styles.navLinkActive : ""}`}
            >
              Patients
            </Nav.Link>
            <Nav.Link 
              as={Link} 
              to="/scheduling" 
              className={`${styles.navLink} ${location.pathname === "/scheduling" ? styles.navLinkActive : ""}`}
            >
              Scheduling
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link 
              as={Link} 
              to="/profile" 
              className={`${styles.navLink} ${location.pathname === "/profile" ? styles.navLinkActive : ""}`}
            >
              Profile
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>

    </Navbar>
  );
};

export default AppNavbar;
