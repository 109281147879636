import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ScrollAnimation = ({title, first, second, third}) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 576 });
  const isMdScreen = useMediaQuery({ maxWidth: 768 });
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className={`d-flex justify-content-center align-items-center flex-column w-100`}>
      <div className='d-flex justify-content-center align-items-center pb-4'>
        <div className='d-flex justify-content-center align-items-center'>{title}</div>
      </div>
      <div className={`d-flex justify-content-center justify-content-md-around justify-content-lg-center align-items-center w-100 flex-wrap  ${isSmallScreen ? 'flex-column' : 'flex-row'}`}>
        <div className={`py-4 d-flex justify-content-center ${isSmallScreen || isMdScreen ? '' : 'me-5'}`}>
          <div data-aos="fade-up" className='d-flex justify-content-center align-items-center'>
            {first}
          </div>
        </div>
        <div className={`py-4 d-flex justify-content-center ${isSmallScreen || isMdScreen ? '' : 'me-5'}`}>
          <div data-aos="fade-up">
            {second}
          </div>
        </div>
        <div className='py-4 d-flex justify-content-center'>
          <div data-aos="fade-up">
            {third}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollAnimation;




