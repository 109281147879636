
function useComparisonRequests () {

  const abbrevationFix = (address) => {

    const abbreviationMap = {
      "S": "South",
      "N": "North",
      "E": "East",
      "W": "West",
      "St": "Street",
      "Ct": "Court",
      "Rd": "Road",
      "Ave": "Avenue",
      "Blvd": "Boulevard",
      "Pkwy": "Parkway",
      "Pl": "Place",
      "Ln": "Lane",
      "Aly": "Alley",
      "Anx": "Anex",
      "Arc": "Arcade",
      "Bnd": "Bend",
      "Bch": "Beach",
      "Blf": "Bluff",
      "Blfs": "Bluffs",
      "Btm": "Bottom",
      "Br": "Branch",
      "Brg": "Bridge",
      "Brk": "Brook",
      "Brks": "Brooks",
      "Byp": "Bypass",
      "Cp": "Camp",
      "Cyn": "Canyon",
      "Cpe": "Cape",
      "Cswy": "Causeway",
      "Ctr": "Center",
      "Ctrs": "Centers",
      "Cir": "Circle",
      "Cirs": "Circles",
      "Clf": "Cliff",
      "Clfs": "Cliffs",
      "Clb": "Club",
      "Cmn": "Common",
      "Cmns": "Commons",
      "Cor": "Corner",
      "Cors": "Corners",
      "Crse": "Course",
      "Cts": "Courts",
      "Cv": "Cove",
      "Cvs": "Coves",
      "Crk": "Creek",
      "Cres": "Crescent",
      "Crst": "Crest",
      "Xing": "Crossing",
      "Xrd": "Crossroad",
      "Xrds": "Crossroads",
      "Curv": "Curve",
      "Dl": "Dale",
      "Dm": "Dam",
      "Dv": "Divide",
      "Dr": "Drive",
      "Drs": "Drives",
      "Est": "Estate",
      "Ests": "Estates",
      "Expy": "Expressway",
      "Ext": "Extension",
      "Exts": "Extensions",
      "Fls": "Falls",
      "Fry": "Ferry",
      "Fld": "Field",
      "Flds": "Fields",
      "Flt": "Flat",
      "Flts": "Flats",
      "Frd": "Ford",
      "Frds": "Fords",
      "Frst": "Forest",
      "Frg": "Forge",
      "Frgs": "Forges",
      "Frk": "Fork",
      "Frks": "Forks",
      "Ft": "Fort",
      "Fwy": "Freeway",
      "Gdn": "Garden",
      "Gdns": "Gardens",
      "Gtwy": "Gateway",
      "Gln": "Glen",
      "Glns": "Glens",
      "Grn": "Green",
      "Grns": "Greens",
      "Grv": "Grove",
      "Grvs": "Groves",
      "Hbr": "Harbor",
      "Hbrs": "Harbors",
      "Hvn": "Haven",
      "Hts": "Heights",
      "Hwy": "Highway",
      "Hl": "Hill",
      "Hls": "Hills",
      "Holw": "Hollow",
      "Inlt": "Inlet",
      "Is": "Island",
      "Iss": "Islands",
      "Jct": "Junction",
      "Jcts": "Junctions",
      "Ky": "Key",
      "Kys": "Keys",
      "Knl": "Knoll",
      "Knls": "Knolls",
      "Lk": "Lake",
      "Lks": "Lakes",
      "Lndg": "Landing",
      "Lgts": "Lights",
      "Lf": "Loaf",
      "Lck": "Lock",
      "Lcks": "Locks",
      "Ldg": "Lodge",
      "Mnr": "Manor",
      "Mnrs": "Manors",
      "Mdw": "Meadow",
      "Mdws": "Meadows",
      "Ml": "Mill",
      "Mls": "Mills",
      "Msn": "Mission",
      "Mtwy": "Motorway",
      "Mt": "Mount",
      "Mtn": "Mountain",
      "Mtns": "Mountains",
      "Nck": "Neck",
      "Orch": "Orchard",
      "Psge": "Passage",
      "Plz": "Plaza",
      "Pln": "Plain",
      "Pt": "Point",
      "Prt": "Port",
      "Pr": "Prairie",
      "Radl": "Radial",
      "Rnch": "Ranch",
      "Rpds": "Rapids",
      "Rst": "Rest",
      "Rdg": "Ridge",
      "Rte": "Route",
      "Shls": "Shoals",
      "Shr": "Shore",
      "Skwy": "Skyway",
      "Spg": "Spring",
      "Spgs": "Springs",
      "Sq": "Square",
      "Sta": "Station",
      "Strm": "Stream",
      "Smt": "Summit",
      "Ter": "Terrace",
      "Trak": "Track",
      "Trfy": "Trafficway",
      "Trl": "Trail",
      "Trlr": "Trailer",
      "Tunl": "Tunnel",
      "Tpke": "Turnpike",
      "Upas": "Underpass",
      "Un": "Union",
      "Vly": "Valley",
      "Vw": "View",
      "Vlg": "Village",
      "Vis": "Vista",
      "Wy": "Way",
    };

    const words = address.split(/[ ,]+/);

    const fullWords = words.map(word => {
      const abbreviationToFull = abbreviationMap[word];
      return abbreviationToFull ? abbreviationToFull : word;
    });
  
    const fullAddress = fullWords.join(" ");
  
    return fullAddress.replace(/,/g, '').trim().toLowerCase();

  };

  return {
    abbrevationFix
  }
};

export default useComparisonRequests;