import { createContext } from "react";

export const UserContext = createContext(undefined);

export const AccessTokenContext = createContext(undefined);

export const GroupsContext = createContext(undefined);

export const UserFound = createContext(undefined);


