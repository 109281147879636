import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useAuth0 } from "@auth0/auth0-react";

import styles from './home.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faCheck, faCalendarWeek, faRoute, faMapLocationDot, faNoteSticky } from '@fortawesome/free-solid-svg-icons';

import StepsCarousel from '../Carousel/Steps_Carousel';
import SmallCarousel from '../Carousel/Small_Carousel';
import IntroNav from '../Nav/Intro_Nav';
import ScrollAnimation from '../Animation/Scroll_Animation';
import ScrollAnimation2 from '../Animation/Scroll_Animation2';
import Divider from '../Utility/Divider';

import Join from '../../assets/images/Join.png';
import CreatePatient from '../../assets/images/Create_Patient.png';
import Schedule from '../../assets/images/Schedule.png';

import ScrollAnimationSingle from '../Animation/Scroll_Animation_Single';
import VideoPlayer from '../../Modal_Components/Video_Player';
import FloatingModal from '../modals/Floating_Modal';
import Waitlist from '../../Modal_Components/Waitlist';

const Home = () => {
  const { loginWithRedirect } = useAuth0();
  const isSmallScreen = useMediaQuery({ maxWidth: 576 });
  const SASubtitle = 'var(--subtitle)';
  const [videoId, setVideoId] = useState('');
  const [show, setShow] = useState(false);
  const [waitlist, setwaitlist] = useState(false);
  
  const handleVideo = (link) => {
    setVideoId(link);
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
    setwaitlist(false);
  }

  const handleWaitlist = () => {
    setwaitlist(true);
  }

  const joinButton = () => {
    return (
      <div className={`p-3 d-flex justify-content-center align-items-center`}>
        <Button className={styles.joinBtn} onClick={() => loginWithRedirect()}>Let's Go!</Button>
      </div>
    );
  }

  const joinFreeButton = () => {
    return (
      <div className={`pt-1 d-flex justify-content-left align-items-left`}>
        <Button className={styles.joinBtn} onClick={() => loginWithRedirect()}>Join Free</Button>
      </div>
    );
  }

  const joinWaitlist = () => {
    return (
      <div className={`pt-1 d-flex justify-content-left align-items-left`}>
        <Button className={styles.joinBtn} onClick={() => handleWaitlist()}>Join Waitlist</Button>
      </div>
    );
  }

  const joinWaitlistSecondary = () => {
    return (
      <div className={`pt-1 d-flex justify-content-left align-items-left`}>
        <Button className={styles.joinBtnSecondary} onClick={() => handleWaitlist()}>Join Waitlist</Button>
      </div>
    );
  }

  const featureItem = (icon, title, content, link) => {
    return (
      <div className='h-100'>
        <div className={`${styles.featureItem} d-flex h-100`}>
          {icon}
          <h4 className={`${styles.subtitle} p-2`}>{title}</h4>
          {title === "Automatic Grouping" || title === "Efficient Routing" ? <p>(Coming Soon)</p> : null}
          <div className={styles.featureContent}>
            {content}
          </div>
          <div className={`${title === "Automatic Grouping" || title === "Efficient Routing" ? 'd-none' : 'd-flex justify-content-end align-items-end w-100 mt-3'}`}>
            <Button variant="link" style={{ color: SASubtitle }} onClick={() => handleVideo(link)}>Show Me</Button>
          </div>
        </div>
      </div>
    );
  }

  const mainTitle = () => {
    return (
      <div id='home' className={`${styles.custTitleContainer} d-flex flex-column justify-content-center align-items-center`}>
        <Container>
          <Row className='d-flex flex-column justify-content-center align-items-center text-center'>
            <Col xs={12}>
              <div className={`${styles.titleContainer} py-3`}>
                <h1 className={styles.saTitle}>There's an Easier Way to <span className={styles.accentWord}>Schedule</span> Your <span className={styles.accentWord}>Home Health</span> Patients.</h1>
              </div>
              <div className={`${styles.titleContainer} pb-3`}>
                <div className={`${styles.saSubtitle}`}>Save time, schedule faster, and stop the frustration with MySchedulePal, your simple online scheduling tool.</div>
              </div>  
              <Row className='d-flex justify-content-center py-3'>
                <Col><div className={`${styles.saSmallTitle} d-flex justify-content-center`}><SmallCarousel /> </div> </Col>
              </Row>  
              <div className='d-flex justify-content-center'>
                {joinWaitlist()}
              </div>         
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  const howItWorksLarge = () => {
    return (
      <div>
        <Container>
          <Row className='d-flex justify-content-center align-items-center'>
            <Col className='mb-4'>
              <div className={`d-flex justify-content-center flex-column align-items-center pt-5`}>
                <h2 className={`${styles.saSecondaryTitleDark} pb-3 text-center`}>Start Scheduling in 3 Easy Steps!</h2>
                <div className={`d-flex align-items-center justify-content-center ${styles.saSubtitle} text-center`}>Onboard and start scheduling in less than 30 Minutes.</div>
              </div>
            </Col>
            <Col>
              <div className="py-5">
                <StepsCarousel />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  const howItWorksSmall = () => {
    const carouselItem = (num, title, content, image) => {
      return (
        <Carousel.Item className={styles.custCarouselItem}>
          <div className={styles.imageWrapper}>
            <img
              className={`${styles.innerImage} d-block w-100`}
              src={image}
              alt={title}
            />
          </div>
          <Carousel.Caption className={styles.custCarouselCaption}>
            <div className={styles.stepsNum}>{num}</div>
            <div className={styles.stepsTitle}>{title}</div>
            <div className={`${styles.stepsContent} pt-4`}>{content}</div>
          </Carousel.Caption>
        </Carousel.Item>
      );
    }

    return (
      <div className='d-flex flex-column justify-content-center align-items-center py-5'>
        <div className={`d-flex justify-content-center flex-column align-items-center pb-3`}>
          <h2 className={`${styles.saSecondaryTitleDark} pb-3 text-center`}>Start in 3 Easy Steps!</h2>
          <div className={`d-flex align-items-center justify-content-center ${styles.saSubtitle} text-center`}>Start scheduling in less than 30 Minutes.</div>
        </div>
        <Carousel className='px-4'>
          {carouselItem('01', 'Join for Free', "Creating your account takes less than 5 minutes.", Join)}
          {carouselItem('02', 'Create Pts', "We get it—retyping addresses is a drag. We use autocomplete!", CreatePatient)}
          {carouselItem('03', 'Schedule!', "Drag and drop your patients into the calendar and test the drive time. It takes 2 clicks to test!", Schedule)}
        </Carousel>
      </div>
    );
  }

  const features = () => {
    return (
      <Container>
        <Stack className='mt-5'>
          <div className={`d-flex justify-content-start align-items-start w-100 flex-column`}>
            <h2 className={`${styles.saSecondaryTitle} ${styles.halfWidth} d-flex align-items-center pb-3`}>Our therapists save time and are free to be with their families.</h2>
            <div className={`${styles.secondarySubtitle} text-left pb-3`}>How much time are you wasting juggling your schedule?</div>
            <ScrollAnimationSingle component={<div className={`${styles.saSmallTitleSecondary} text-left pb-3`}>Join 350+ Therapists on our waitlist!</div>} time={1000} />
            {joinWaitlistSecondary()}
          </div>
          <ScrollAnimationSingle 
            component={<Container className='py-5'>
              <Row className='py-4'>
                <Col xs={12} md={6} className={styles.smallerFeatureScreen}>
                  {featureItem(
                    <FontAwesomeIcon icon={faCalendarWeek} style={{ color: SASubtitle }} size="3x" />,
                    "Test Drive-Time Viability",
                    "Stop using google maps to constantly type in one address at a time and test the sequentially. Quickly edit your schedule and instantly test which visits will work in terms of drive time viability.",
                    "eDZATkDi4Fc",
                  )}
                </Col>
                <Col xs={12} md={6}>
                  {featureItem(
                    <FontAwesomeIcon icon={faMapLocationDot} size="3x" />,
                    "Automatic Grouping",
                    "No more staring at the map trying to figure out which patients should be seen together. Sometimes, the groups you establish don't even work because of scheduling conflicts. We'll do the heavy lifting while you focus on getting the patient to answer the phone!",
                    "",
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} className={styles.smallerFeatureScreen}>
                  {featureItem(
                    <FontAwesomeIcon icon={faRoute} size="3x" />,
                    "Efficient Routing",
                    "Save time and gas money by finding the most efficient route for your day. Not all of your patients will agree to the route but it's a great place to start!",
                    "",
                  )}
                </Col>
                <Col xs={12} md={6}>
                  {featureItem(
                    <FontAwesomeIcon icon={faNoteSticky} size="3x" />,
                    "Track Patient Info",
                    `Patients tend to have odd requests. "I can't be seen before 10am, Use the side door, I have physical therapy on Tuesdays". Keep all these notes in one place, no need to review/keep old notes or look at several different tools. Keep it here.`,
                    "3XxTM3hTWCw",
                  )}
                </Col>
              </Row>
            </Container>}
            time={1000}
          />
        </Stack>
      </Container> 
    );
  }

  const pricing = () => {
    const title = () => {
      return (
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <h2 className={`${styles.saSecondaryTitle} text-center pt-5 pb-3`}>Join the Community</h2>
          <div className={`${styles.secondarySubtitle} text-center pb-3`}>Get access to <span className={styles.accentWordDark}>MySchedulePal's</span> toolkit and start saving time today.</div>
          <ScrollAnimationSingle component={<div className={`${styles.saSmallTitleSecondary} text-left pb-3`}>Join 350+ Therapists on our waitlist!</div>} time={1000} />
        </div>
      );
    }

    const pricingLite = () => {
      return (
        <div className={`${styles.border} d-flex flex-column justify-content-end align-items-start ${styles.pricingPadding}`}>
          <div className={`${styles.saSubtitle} ${styles.mobilePricingPadding} p-2`}>Lite (Free)</div>
          <div className={`${styles.contentPricing} ${styles.mobilePricingPadding} p-2`}><span className='me-2'><FontAwesomeIcon color={SASubtitle} icon={faCheck} /></span>Unlimited Testing</div>
          <div className={`${styles.contentPricing} ${styles.mobilePricingPadding} p-2`}><span className='me-2'><FontAwesomeIcon color={SASubtitle} icon={faCheck} /></span>Track Patient Schedule Notes</div>
          <div className={`${styles.contentPricing} ${styles.mobilePricingPadding} p-2`}><span className='me-2'><FontAwesomeIcon color={SASubtitle} icon={faCheck} /></span>Early Bird Access to Updates</div>
          <div className={`p-2 d-flex justify-content-center align-items-center`}>
            {joinWaitlist()}
          </div>
        </div>
      );
    }

    const pricingPro = () => {
      return (
        <div className={`${styles.border} d-flex flex-column justify-content-end align-items-start ${styles.pricingPadding}`}>
          <div className={`${styles.saSubtitle} ${styles.mobilePricingPadding} p-2`}>Pro</div>
          <div className={`${styles.contentPricing} ${styles.mobilePricingPadding} p-2`}><span className='me-2'><FontAwesomeIcon color={SASubtitle} icon={faCheck} /></span>Unlimited Testing</div>
          <div className={`${styles.contentPricing} ${styles.mobilePricingPadding} p-2`}><span className='me-2'><FontAwesomeIcon color={SASubtitle} icon={faCheck} /></span>Track Patient Schedule Notes</div>
          <div className={`${styles.contentPricing} ${styles.mobilePricingPadding} p-2`}><span className='me-2'><FontAwesomeIcon color={SASubtitle} icon={faCheck} /></span>Early Bird Access to Updates</div>
          <div className={`${styles.contentPricing} ${styles.mobilePricingPadding} p-2`}><span className='me-2'><FontAwesomeIcon color={SASubtitle} icon={faCheck} /></span>Automatic Geographic Grouping</div>
          <div className={`${styles.contentPricing} ${styles.mobilePricingPadding} p-2`}><span className='me-2'><FontAwesomeIcon color={SASubtitle} icon={faCheck} /></span>Color Coordinated Tracking</div>
          <div className={`p-2 d-flex justify-content-center align-items-center`}>
            {joinWaitlist()}
          </div>
        </div>
      );
    }

    const pricingPremium = () => {
      return (
        <div className={`${styles.border} d-flex flex-column justify-content-end align-items-start ${styles.pricingPadding}`}>
          <div className={`${styles.saSubtitle} ${styles.mobilePricingPadding} p-2`}>Premium</div>
          <div className={`${styles.contentPricing} ${styles.mobilePricingPadding} p-2`}><span className='me-2'><FontAwesomeIcon color={SASubtitle} icon={faCheck} /></span>Unlimited Testing</div>
          <div className={`${styles.contentPricing} ${styles.mobilePricingPadding} p-2`}><span className='me-2'><FontAwesomeIcon color={SASubtitle} icon={faCheck} /></span>Track Patient Schedule Notes</div>
          <div className={`${styles.contentPricing} ${styles.mobilePricingPadding} p-2`}><span className='me-2'><FontAwesomeIcon color={SASubtitle} icon={faCheck} /></span>Early Bird Access to Updates</div>
          <div className={`${styles.contentPricing} ${styles.mobilePricingPadding} p-2`}><span className='me-2'><FontAwesomeIcon color={SASubtitle} icon={faCheck} /></span>Automatic Geographic Grouping</div>
          <div className={`${styles.contentPricing} ${styles.mobilePricingPadding} p-2`}><span className='me-2'><FontAwesomeIcon color={SASubtitle} icon={faCheck} /></span>Efficient Routing</div>
          <div className={`${styles.contentPricing} ${styles.mobilePricingPadding} p-2`}><span className='me-2'><FontAwesomeIcon color={SASubtitle} icon={faCheck} /></span>AI Smart Scheduling</div>
          <div className={`${styles.contentPricing} ${styles.mobilePricingPadding} p-2`}><span className='me-2'><FontAwesomeIcon color={SASubtitle} icon={faCheck} /></span>Community Tips</div>
          <div className={`p-2 d-flex justify-content-center align-items-center`}>
            {joinWaitlist()}
          </div>
        </div>
      );
    }

    return (
      <div className={`${styles.allPricingContents} p-5`}>
        <ScrollAnimation title={title()} first={pricingLite()} second={pricingPro()} third={pricingPremium()}/>
      </div>
    );
  }

  const hipaa = () => {
    return (
      <ScrollAnimation2 widget={
        <Container>
          <Row>
            <Col xs={12} className='d-flex flex-column justify-content-center align-items-center'>
              <h2 className={`${styles.saSecondaryTitleDark} py-4 text-center`}>HIPAA Compliant</h2>
              <div className={`${styles.saSubtitle} text-center pb-4`}>Top-notch encryption and security to keep your health info (PHI) locked down—because HIPAA says so, and we take that seriously!</div>
              <div className="pb-3">
                {joinWaitlist()}
              </div>
            </Col>
          </Row>
        </Container>
      } />
      
    );
  }

  const aboutUs = () => {
    return (
      <Container id='about'>
        <div className='py-3'>
          <div className='d-flex justify-content-start align-items-start'>
            <h2 className={`${styles.saSecondaryTitle} pb-3`}>About Us</h2>
          </div>
          <div className={`${isSmallScreen ? 'd-none' : 'd-flex align-items-center'}`} md={1}><Divider /></div>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className={styles.aboutUsContent}>
              I watched my wife, a Pediatric SLPA, spend hours each week juggling sticky notes, a planner, and Google Maps to piece together her schedule. The stress kept building, so we decided there had to be a better way. With a few ideas in mind, I built a prototype—and it worked! I then spoke with as many home health professionals as I could to truly understand their challenges. MySchedulePal was created to give you back your time and reduce the stress of home health.
            </div>
          </div>
        </div>
      </Container>
    )
  }

  const callToAction = () => {
    return (
      <ScrollAnimation2 widget={
        <Container>
          <Row>
            <Col xs={12} className='d-flex flex-column justify-content-center align-items-center'>
              <h2 className={`${styles.saSecondaryTitleDark} py-4 text-center`}>Take Back Your Time & Make Scheduling Easier</h2>
              <ScrollAnimationSingle component={<div className={`${styles.saSmallTitle} text-left pb-3`}>Join 350+ Therapists on our waitlist!</div>} time={1000} />
              {joinWaitlist()}
            </Col>
          </Row>
        </Container>
      } />
      
    );
  }

  return (
    <Container-Fluid>
      <IntroNav />
      <Stack className={styles.body}>
        {mainTitle()}
        <div className={`${styles.featureContainer} d-flex flex-column`}  id='features'>
          {features()}
        </div>
        <div className={`${styles.howContainer} d-flex flex-column`} id='steps'>
          <div className='d-none d-lg-inline'>
            {howItWorksLarge()}
          </div>
          <div className='d-inline d-lg-none'>
            {howItWorksSmall()}
          </div>
        </div>
        <div className={`${styles.pricingContainer} pt-2 d-flex flex-column`} id='pricing'>          
          {pricing()}
        </div>
        <div className={`${styles.hipaaContainer} pt-2 d-flex flex-column`} id='hipaa'>
          {hipaa()}
        </div>
        <div className={`${styles.aboutUsContainer} pt-2 mt-3 pb-3 d-flex flex-column`} id='about'>          
          {aboutUs()}
        </div>
        <div className={`${styles.actionContainer} py-2 d-flex flex-column`}>          
          {callToAction()}
        </div>
      </Stack>
      <FloatingModal 
        title="Example!" 
        component={<VideoPlayer link={videoId} />} 
        show={show} 
        handleClose={handleClose}
      />
      <FloatingModal 
        title="Join 350+ Therapists" 
        component={<Waitlist handleClose={handleClose}/>} 
        show={waitlist} 
        handleClose={handleClose}
      />
    </Container-Fluid>
  );
}

export default Home;