import React, {useEffect, useContext, useState, useCallback} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from 'react-query';

import { UserContext, AccessTokenContext } from '../../context/context.js';
import { useAlert } from "../../context/alert-context.js";
import useDistanceRequests from '../../hooks/distance-request.js';
import usePatientRequests from '../../hooks/patient-requests.js';
import useScheduleRequests from '../../hooks/schedule-requests.js';
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import './calendar-extra.css';
import './calendar.css';
import { Form, Button, InputGroup, Row, Col, Container, Stack } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';

import { momentLocalizer, Calendar as BigCalendar } from 'react-big-calendar';
import PatientScheduleList from '../Patient_Schedule_List/Patient_Schedule_List.js';
import EditParameters from '../edit-parameters.js';
import ErrorScreen from '../../components/Error-Screen.js';
import ModalComponent from '../../static/modals/Modal_Component.js';
import EventModal from '../../Modal_Components/Event_Modal.js';

const DnDCalendar = withDragAndDrop(BigCalendar);
const localizer = momentLocalizer(moment);


function Calendar(props) {
  const user = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);
  
  const { showAlert } = useAlert();
  const { getPatients } = usePatientRequests();
  const { getTimeDistances } = useDistanceRequests();
  const { saveEventToSchedule, updateEvent, getUserSchedule, clearScheduleFromDay, deleteEvent } = useScheduleRequests();

  const [myEvents, setMyEvents] = useState([]);
  const [draggedClient, setDraggedClient] = useState();
  const [calViewChange, setCalViewChange] = useState(false);
  const [viewFocus, setViewFocus] = useState({
    edit: false,
    view: 'Patient'
  });
  const [testDay, setTestDay] = useState(undefined);
  const [therapistParameters, setTherapistParameters] = useState({
    workingDays: user.workingDays,
    sessionLength: user.sessionLength,
    bufferTime: user.bufferTime,
    startTime: user.startTime
  });
  const [viewStartDate, setViewStartDate] = useState(null);
  const [viewEndDate, setViewEndDate] = useState(null);
  const [stillFetching, setStillFetching] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [moveEventQueue, setMoveEventQueue] = useState([]);
  const [isProcessingMove, setIsProcessingMove] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const [eventModalProps, setEventModalProps] = useState({});

  const { data: homes, homeStatus } = useQuery(["homes"], 
    () => getPatients(user.authId, accessToken)
  );

  const { data: dbSchedule, dbScheduleStatus, refetch } = useQuery(["schedule"], 
    () => getUserSchedule(user.authId, accessToken), {
      onSuccess: (data) => {
        if(data.status === undefined) {
          fillInCalendar(data); 
          setHasError(false);
        } else {
          showAlert(
            "We're unable to load your current schedule. Please try refreshing your page. If this issue persists, kindly provide us with feedback. Thanks for your patience.", 
            "Oh, Snap", 'danger'
          );
          setHasError(true);
        }
        
      },
      onError: () => {
        showAlert(
          "We're unable to load your current schedule. Please try refreshing your page. If this issue persists, kindly provide us with feedback. Thanks for your patience.", 
          "Oh, Snap", 'danger'
        );
        setHasError(true);
      }
    }
  );  

  const handleNavigate = date => {
    let newStart, newEnd;

    const newDate = new Date(date).getTime();
    const viewStart = new Date(viewStartDate).getTime();
    const viewEnd = new Date(viewEndDate).getTime();

    if(newDate > viewStart && newDate > viewEnd) {
      newStart = localizer.add(viewStartDate, 7, 'days');
      newEnd = localizer.add(viewEndDate, 7, 'days');
    } else {
      newStart = localizer.add(viewStartDate, -7, 'days');
      newEnd = localizer.add(viewEndDate, -7, 'days');
    }

    setViewStartDate(newStart);
    setViewEndDate(newEnd);
  }

  useEffect(() => {
    const currentDay = new Date(moment().toLocaleString('en-US', { weekday: 'short' })).getDay();
    const startDate = localizer.add(moment(), -currentDay, 'days');
    const endDate = localizer.add(moment(), (6-currentDay), 'days'); 

    setViewStartDate(startDate);
    setViewEndDate(endDate);
  }, []);

  useEffect(() => {
    const timeSlots = document.querySelectorAll('.rbc-time-slot');
    const timeSlotsGroups = document.querySelectorAll('.rbc-timeslot-group');

    for (let i = 0; i < timeSlots.length; i++) {
      if (i % 6 !== 0) {
        const label = timeSlots[i].querySelector('.rbc-label');
        if (label) {
          label.style.display = 'none';
        }
      }
    }

    for (let i = 0; i < timeSlotsGroups.length; i++) {
      if (i % 6 === 0) {
        const slotGroup = timeSlotsGroups[i];
        slotGroup.style.border = '1px solid #a5a4a4';
      }
    }
  }, [calViewChange]);
  
  useEffect(() => {
    handleRefetch();
  }, [props.isOpen]);
  
  const handleRefetch = async () => {
    const { data, error, failureCount, isFetching } = await refetch();
    if (data.error) {
      console.log('this is working')
    }

    if (data) {
      setStillFetching(false);
    }
  }

  const changeView = () => {
    setCalViewChange(!calViewChange);
  }
  
  const eventPropGetter = (event) => {
    let className = '';
  
    if (event.isViableDest === false && event.isViableOrg === true) {
      className += 'orgVDestN ';
    }
  
    if (event.isViableDest === true && event.isViableOrg === false) {
      className += 'orgNDestV ';
    }
  
    if (event.isViableDest === false && event.isViableOrg === null) {
      className += 'orgNullDestN ';
    }
  
    if (event.isViableDest === null && event.isViableOrg === false) {
      className += 'orgNDestNull ';
    }
  
    if (event.isViableDest === null && event.isViableOrg === true) {
      className += 'orgVDestNull ';
    }
  
    if (event.isViableDest === true && event.isViableOrg === null) {
      className += 'orgNullDestV ';
    }
  
    if (event.isViableDest === false && event.isViableOrg === false) {
      className += 'bothNotViable ';
    }
  
    if (event.isViableDest === true && event.isViableOrg === true) {
      className += 'bothViable ';
    }
  
    if (event.additional) {
      className += 'additional-day ';
    }
    
    className = className.trim();
  
    return { className }
  }   

  const eventViability = async (viabilityData) => {
    if(viabilityData) {
      const response = await getUserSchedule(user.authId, accessToken);
      const retrieveId = (event) => {
        const matchedEvent = response.find((ev) => ev.eventId === event.eventId);
         matchedEvent._id;
        return matchedEvent._id;
      }

      if(response.status !== undefined) {
        showAlert(
          "We're experiencing difficulty retrieving this patient's data. Please refresh your page and try again. If the problem continues, kindly provide us with feedback so we can assist you further.", "Oh, Snap!", 'danger'
        );
      } else {
        for (let i = 0; i < viabilityData.length; i++) {
          const element = viabilityData[i];

          let origin = myEvents.find((event) => event.eventId === element.originId);
          origin.isViableOrg = element.isViable;
          const filteredOriginState = myEvents.filter((event) => event.eventId !== element.originId);
          if(!origin._id) {
            const idToUse = retrieveId(origin);
            origin._id = idToUse;
          }
          const responseO = await updateEvent(origin, accessToken);
          if(responseO.status === 200) {
            setMyEvents(() => {
              return [...filteredOriginState, {...origin}]
            });
          } else {
            showAlert(
              "We're experiencing difficulty verifying the details of your schedule. Please ensure that you have a stable internet connection and try refreshing the page. If the problem continues, kindly provide us with feedback so we can assist you further.", 
              "Trouble Processing Your Schedule", 
              'danger'
            );
            break;
          }
          
          let destination = myEvents.find((event) => event.eventId === element.destinationId);
          destination.isViableDest = element.isViable;
          const filteredDestinationState = myEvents.filter((event) => event.eventId !== element.destinationId);
          if(!destination._id) {
            const idToUse = retrieveId(destination);
            destination._id = idToUse;
          }
          const responseD = await updateEvent(destination, accessToken);
          if(responseD.status === 200) {
            setMyEvents(() => {
              return [...filteredDestinationState, {...destination}]
            });
          } else {
            showAlert(
              "We're experiencing difficulty verifying the details of your schedule. Please ensure that you have a stable internet connection and try refreshing the page. If the problem continues, kindly provide us with feedback so we can assist you further.", "Trouble Processing Your Schedule", 'danger'
            );
            break;
          }
        }
      }
    } 
  }

  const updateViability = async (scheduleOfDay, event) => {
    const movedIndex = scheduleOfDay.findIndex((ev) => ev.eventId === event.eventId);

    const origin = scheduleOfDay[movedIndex - 1] ?? undefined;
    const destination = scheduleOfDay[movedIndex + 1] ?? undefined;

    if(origin && origin.isViableOrg !== null) {
      let originToChange = myEvents.find((ev) => ev.eventId === origin.eventId);
      originToChange.isViableOrg = null;
      const filteredOriginState = myEvents.filter((ev) => ev.eventId !== originToChange.eventId);

      const response = await updateEvent(originToChange, accessToken);
      if(response.status === 200) {
        setMyEvents(() => {
          return [...filteredOriginState, {...originToChange}]
        });
      } else {
        showAlert(
          "We're experiencing difficulty verifying the details of your schedule. Please ensure that you have a stable internet connection and try refreshing the page. If the problem continues, kindly provide us with feedback so we can assist you further.", 
          "Trouble Processing Your Schedule", 
          'danger'
        );
      }
    }

    if(destination && destination.isViableDest !== null) {
      let destinationToChange = myEvents.find((ev) => ev.eventId === destination.eventId);
      destinationToChange.isViableDest = null;
      const filteredDestinationState = myEvents.filter((ev) => ev.eventId !== destinationToChange.eventId);

      const response = await updateEvent(destinationToChange, accessToken);
      if(response.status === 200) {
        setMyEvents(() => {
          return [...filteredDestinationState, {...destinationToChange}]
        });
      } else {
        showAlert(
          "We're experiencing difficulty verifying the details of your schedule. Please ensure that you have a stable internet connection and try refreshing the page. If the problem continues, kindly provide us with feedback so we can assist you further.", 
          "Trouble Processing Your Schedule", 
          'danger'
        );
      }
    }
  }

  const fillInCalendar = (dbSchedule) => {
    const schedule = dbSchedule.map((event) => {
      const startTime = new Date(event.start);
      const endTime = new Date(event.end);

      event.start = startTime;
      event.end = endTime;

      return event; 
    });

    
    setMyEvents([...schedule]);
  }

  const newEvent = useCallback(async (event) => {
    const response = await saveEventToSchedule(user.authId, event, accessToken);
    if(response.status === 201) {
      setMyEvents((prev) => {
        return [...prev, { ...event }]
      });
    } else {
      showAlert(`ERROR: ${response.message}`, 'Oh, Snap!', 'danger');
    }
    
  },[setMyEvents]);

  const onDropFromOutside = useCallback(
    ({ start, allDay: isAllDay }) => {

      const end = new Date(start.getTime() + therapistParameters.sessionLength * 60000);

      const {client, address, coordinates, additional} = draggedClient;

      const event = {
        eventId: uuidv4(),
        title: client,
        address: address,
        coordinates: coordinates,
        additional: additional,
        start,
        end,
        isAllDay,
        isViableOrg: null,
        isViableDest: null,
      }

      newEvent(event);
    },
    [newEvent, draggedClient]
  );

  const handleMoveEvent = async ({ event, start, end, allDay: isAllDay }) => {
    const viewStart = new Date(viewStartDate).getTime();
    const viewEnd = new Date(viewEndDate).getTime();
    const moveFromDay = event.start.toLocaleString('en-US', { weekday: 'long' });
    const moveToDay = start.toLocaleString('en-US', { weekday: 'long' });

    const scheduleOfMovedFrom = myEvents.filter((ev) => {
      const day = ev.start.toLocaleString('en-US', { weekday: 'long' });

      const start = new Date(ev.start).getTime();

      return day === moveFromDay && (start >= viewStart && start <= viewEnd);
    }).sort((a, b) => a.start - b.start);

    const proceedWithMove = () => {
       moveEvent({ event, start, end, allDay: isAllDay })
        .then(() => {
          updateViability(scheduleOfMovedFrom, event);

          const scheduleOfMovedTo = myEvents.filter((ev) => {
            const day = ev.start.toLocaleString('en-US', { weekday: 'long' });
      
            const start = new Date(ev.start).getTime();
      
            return day === moveToDay && (start >= viewStart && start <= viewEnd);
          }).sort((a, b) => a.start - b.start);

          updateViability(scheduleOfMovedTo, event);
        })
        .catch((error) => {
          console.error('Failed to move event:', error);
          props.openModal('LoadingModal', '');
          setMyEvents(myEvents);
          showAlert(
            "We're experiencing difficulty verifying the move you're attempting to make. Please ensure that you have a stable internet connection and try refreshing the page. If the problem continues, kindly provide us with feedback so we can assist you further.",
            "Trouble Processing Your Schedule",
            'danger'
          );
        });
    }

    if(!event._id) {
      const response = await getUserSchedule(user.authId, accessToken);
      if(response.status === undefined) {
        const matchedEvent = response.find((ev) => ev.eventId === event.eventId);
        event._id = matchedEvent._id;

        proceedWithMove();
      } else {
        showAlert(
          "We're experiencing difficulty retrieving this patient's data. Please refresh your page and try again. If the problem continues, kindly provide us with feedback so we can assist you further.", 
          "Oh, Snap!", 
          'danger'
        );
      }
    } else {
      proceedWithMove();
    }
    
  }

  const processEventQueue = useCallback(async () => {
    if (isProcessingMove || moveEventQueue.length === 0) return;

    setIsProcessingMove(true);
    const { event, start, end, isAllDay, resolve, reject } = moveEventQueue[0];

    try {
      const existingEvent = myEvents.find((ev) => ev.eventId === event.eventId);
      const filteredState = myEvents.filter((ev) => ev.eventId !== event.eventId);

      existingEvent.isViableOrg = null;
      existingEvent.isViableDest = null;

      let newEvent = event;
      newEvent.start = start;
      newEvent.end = end;
      newEvent.isAllDay = isAllDay;

      const response = await updateEvent(newEvent, accessToken);
      
      if (response.status === 200) {
        setMyEvents([...filteredState, { ...existingEvent, start, end, isAllDay }]);
        resolve();
      } else {
        showAlert(
          "We're experiencing difficulty verifying the move you're attempting to make. Please ensure that you have a stable internet connection and try refreshing the page. If the problem continues, kindly provide us with feedback so we can assist you further.",
          "Trouble Processing Your Schedule",
          'danger'
        );
        reject(new Error("Failed to update event"));
      }
    } catch (error) {
      reject(error);
    } finally {
      setMoveEventQueue((prevQueue) => prevQueue.slice(1)); 
      setIsProcessingMove(false);
    }
  }, [isProcessingMove, moveEventQueue, myEvents, user.authId, accessToken, setMyEvents, showAlert]);

  useEffect(() => {
    if (!isProcessingMove && moveEventQueue.length > 0) {
      processEventQueue();
    }
  }, [moveEventQueue, isProcessingMove, processEventQueue]);

  const moveEvent = async ({ event, start, end, allDay: isAllDay }) => {
    return new Promise((resolve, reject) => {
      setMoveEventQueue((prevQueue) => [...prevQueue, { event, start, end, isAllDay, resolve, reject }]);
    })
  }

  const handleDragStart = useCallback(
    (name, address, coordinates, additional, eventId) => {
      setDraggedClient({client: name, address: address, coordinates: coordinates, additional: additional, eventId: eventId});
  },[])

  const getSelectedDaySchedule = () => {
    const viewStart = new Date(viewStartDate).getTime();
    const viewEnd = new Date(viewEndDate).getTime();

    const selectedDaySchedule = myEvents.filter((event) => {
      const day = event.start.toLocaleString('en-US', { weekday: 'long' });

      const start = new Date(event.start).getTime();

      return day === testDay && (start >= viewStart && start <= viewEnd);
    }).sort((a, b) => a.start - b.start);

    return selectedDaySchedule;
  }
  
  const testSchedule = async () => {
    const selectedDaySchedule = getSelectedDaySchedule();
    const needsTesting = selectedDaySchedule.filter((ev) => ev.isViableDest === null && ev.isViableOrg === null);

    if(selectedDaySchedule.length === 0) {
      showAlert(
        `Looks like ${testDay} doesn't have any patients to test, you're FREE...for now...`, 
        "You're Free", 
        'warning'
      );
    } else if(needsTesting.length > 0) {
        if(selectedDaySchedule.length > 1) {
          setTestDay(undefined);
          props.openModal('LoadingModal', '');
          const response = await getTimeDistances(selectedDaySchedule, accessToken);
          if(response.status === 200) {
            eventViability(response.message);
          } else {
            showAlert(
              `We ran into an error while testing your schedule. Please try again and provide feedback. Error Message: ${response.message}`, 
              "Oops!", 
              'danger'
            );
          }
        } else {
          showAlert(
            `Please make sure there are at least 2 patients on ${testDay}.`, 
            "Hold Up", 
            'warning'
          );
        }
    } else {
        showAlert(
          `Looks like ${testDay} has already been tested with no changes`, 
          "Hold Up", 
          'warning'
        );
    }
    
  }

  const clearScheduledDay = async () => {
    const selectedDaySchedule = getSelectedDaySchedule();
    const response = await clearScheduleFromDay(accessToken, selectedDaySchedule);
    if(response.status ===204) {
      await handleRefetch();
    } else {
      showAlert(
        "We're unable to remove your current schedule. Please try refreshing your page. If this issue persists, kindly provide us with feedback. Thanks for your patience.", 
        "Oh, Snap", 'danger'
      );
    }
  }

  const handleCloseModal = () => {
    setShowEventModal(false);
  }

  const removeEventFromCal = async () => {
    const response = await deleteEvent(user.authId, eventModalProps.client, accessToken);
    if(response.status === 204) {
      showAlert('', 'Success!',  'success');
      setTimeout(() => {
        handleCloseModal();
        handleRefetch();
      }, 500);
    } else {
      showAlert(`ERROR: ${response.message}`, 'Oh, Snap!', 'danger');
    }
  }

  const selectEvent = async(event) => {
    if(!event._id) {
      const response = await getUserSchedule(user.authId, accessToken);
      if(response.status === undefined) {
        const matchedEvent = response.find((ev) => ev.eventId === event.eventId);
        event._id = matchedEvent._id;
      } else {
        showAlert(
          "We're experiencing difficulty retrieving this patient's data. Please refresh your page and try again. If the problem continues, kindly provide us with feedback so we can assist you further.", "Oh, Snap!", 'danger'
        );
      }
    }
    
    const date = new Date(event.start);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; 
    const day = date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes();

    if(minutes === 0) {
      minutes = '00'
    }

    if(hours === 0) {
      hours = '12'
    }
  
    const clientInfo = {
      title: event.title,
      address: event.address,
      coordinates: event.coordinates,
      id: event._id,
      start: `${month}-${day}-${year} at ${hours}:${minutes}`,
    }
    
    const patientProps = {
      client: clientInfo,
      myEvents: myEvents,
      patients: homes.message,
      view: viewFocus.view
    }

    setEventModalProps(patientProps);
    setShowEventModal(true);
  }  

  const viewCheck = async (event) => {
    if(event.target.id === 'Patient') {
        setViewFocus(prev => ({
          ...prev,
          edit: false,
          view: 'Patient'
        }));
    } else if(event.target.id === 'Edit') {
        setViewFocus(prev => ({
          ...prev,
          edit: true,
          view: 'Edit'
        }));
    } 
  }

  const daySelection = (event) => {
    switch (event) {
      case 'Sunday':
        setTestDay('Sunday');
        break;
      case 'Monday':
        setTestDay('Monday');
        break;
      case 'Tuesday':
        setTestDay('Tuesday');
        break;
      case 'Wednesday':
        setTestDay('Wednesday');
        break;
      case 'Thursday':
        setTestDay('Thursday');
        break;
      case 'Friday':
        setTestDay('Friday');
        break;
      case 'Saturday':
        setTestDay('Saturday');
        break;
      default:
        setTestDay(undefined);
        break;
    }
  }

  const handleTherapistParameters = (params) => {
    setTherapistParameters(params);
  }

  const parseTimeToScrollTime = ()  => {
    const parts = therapistParameters.startTime.split(':');
    const hours = parseInt(parts[0], 10);
    const minutes = parts.length > 1 ? parseInt(parts[1], 10) : 0;
  
    return new Date(1970, 1, 1, hours, minutes);
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className="d-flex flex-column py-3">
        <div className="key">
          <div className="blob-key normal me-1 "></div>
          <p className="des-key">Normal</p>
        </div>
        <div className="key">
          <div className="blob-key add-resch me-1 "></div>
          <p className="des-key">Additional/Rescheduled Visit</p>
        </div>      
      </div>
    </Tooltip>
  );

  if(hasError) {
    return <ErrorScreen />
  }

  return (
    <div className={"container-fluid"}>
      <div className="row my-4">
        {/* Calendar */}
        <div className="col-7 dndCal-container">
          {
            !stillFetching ?
              (
                <DnDCalendar {...props} 
                localizer={localizer} 
                events={myEvents} 
                onDropFromOutside={onDropFromOutside}
                onEventDrop={handleMoveEvent}
                onEventResize={handleMoveEvent}
                eventPropGetter={eventPropGetter}
                onSelectEvent={selectEvent}
                scrollToTime={parseTimeToScrollTime()}
                step={5}
                defaultView="week" 
                onNavigate={handleNavigate}
                onView={changeView}
                resizable
                selectable
                />
              ) : 
              (
                <div className="row">
                  <div className="col">
                    <div><Loading /></div>     
                  </div>
                </div>
              )
            
          }
        </div>

        {/* Display */}
        <div className="col">
          <div className='d-flex justify-content-start align-items-center w-100'>
            <div className='w-50 d-flex'>
              <Form.Select 
                className="SA-form-input" 
                name="testDay" id="testDay" 
                onChange={(event) => daySelection(event.target.value)}
                value={testDay || ''}
              >
                <option disabled value="">Select a Day to Test</option>
                  <option value="Sunday">Sunday</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                  <option value="Saturday">Saturday</option>
              </Form.Select>
            </div>
              <div className='d-flex align-items-center'>
                <Button 
                  onClick={testSchedule} 
                  className="SA-Small-Btn ms-2" 
                  disabled={!testDay}>
                  Test Day
                </Button>
                <Button 
                  onClick={clearScheduledDay} 
                  className="SA-Small-Btn-Danger ms-2" 
                  disabled={!testDay}>
                  Clear All
                </Button>
              </div>
              
          </div>
          
                {/* <button onClick={clearScheduledDay}>Clear All Events</button> */}
             
         

            {/* <div className="row views mb-3">
              <div className="col">
                <div className="btn-group" role="group" aria-label="Basic radio  toggle button group">
                  
                  <input type="radio" className="btn-check view-btn" name="Patient" id="Patient" 
                  checked={viewFocus.view === 'Patient'}  onChange={viewCheck}
                  />
                  <label className="view left-rad" htmlFor="Patient">Patient View</label>

                  <input type="radio" className="btn-check view-btn" name="Edit" id="Edit" checked={viewFocus.view === 'Edit'}  onChange={viewCheck} />
                  <label className="view right-rad" htmlFor="Edit">Customize</label>

                </div>
              </div>
            </div> */}

            <div className="my-3">
              <PatientScheduleList handleDragStart={handleDragStart} homes={homes} homeStatus={homeStatus} myEvents={myEvents} start={viewStartDate} end={viewEndDate} />
            </div>
              
            {/* <div className="row displays">
              {!viewFocus.edit ? (
                <div className="mb-3">
                  <DisplayPatients handleDragStart={handleDragStart} homes={homes} homeStatus={homeStatus} myEvents={myEvents} start={viewStartDate} end={viewEndDate} />
                </div>
                ) : viewFocus.edit ? (
                  <div>
                    <EditParameters therapistParameters={therapistParameters} handleTherapistParameters={handleTherapistParameters} />
                  </div>
                ) : null
              }
            </div> */}
        </div>
      </div>

      <ModalComponent 
        title={"Manage Your Visit"}
        component={<EventModal modalProps={eventModalProps} />}
        show={showEventModal}
        handleAction={removeEventFromCal}
        actionTitle={'Remove Visit'}
        buttonClass={'SA-Small-Btn-Danger'}
        handleClose={handleCloseModal}
      />
    </div>
  )
}

export default Calendar;

    