import React, { useEffect, useState } from "react";

import Loading from "../../pop-ups/loading";
import useComparisonRequests from "../../hooks/comparison-requests";

import styles from "./Patient_Schedule_List.module.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Popover from 'react-bootstrap/Popover';

const PatientScheduleList = ({ handleDragStart, homes, homeStatus, myEvents, start, end }) => {

  const { abbrevationFix } = useComparisonRequests();

  const [remainingPatients, setRemainingPatients]=useState([]);
  const [patient, setPatient] = useState({});
 
  useEffect(() => {
    if(homes && start) {
      const viewStart = new Date(start.setHours(0, 0, 0, 0)).getTime();
      const viewEnd = new Date(end.setHours(23, 59, 59, 999)).getTime();

      const activePatients = homes.message.filter((home) => home.active && home.address !== '');
      
      const eventsUsed = myEvents.filter((event) => {
        const eventStart = new Date(event.start).getTime();
        return eventStart >= viewStart && eventStart <= viewEnd;
      });
  
      const patients = activePatients.map((patient) => {

        const patAddress = abbrevationFix(patient.address);
        const frequency = parseInt(patient.frequency);
        
        const patientsEvents =  eventsUsed.filter((event) => {
          const evAddress = abbrevationFix(event.address);

          return evAddress === patAddress && event.title === `${patient.firstName} ${patient.lastName}`
        });
      
        if(frequency > patientsEvents.length) {
          patient.additional = false;
          return patient;
        };
        
        if(frequency <= patientsEvents.length){
          patient.additional = true;
          return patient;
        };
      });

      patients.sort((a, b) => a.firstName.localeCompare(b.firstName));
  
      setRemainingPatients(patients);
    }
  },[myEvents, start]);

  const handleHover = (p) => setPatient(p);

  const popover = (
    <Popover id={`${styles.popoverBasic}`}>
      <Popover.Header className={styles.popoverHeader} as="h3">{patient.firstName} {patient.lastName}</Popover.Header>
      <Popover.Body>
        {<div>
          <div className="d-flex flex-column mb-2">
            <div className="SA-label-dark-bold d-flex">Primary:</div>
            <div className="SA-Main-Text-Color d-flex flex-wrap">{patient.primaryNumber || '--'}</div>
          </div>
          <div className="d-flex flex-column mb-2">
            <div className="SA-label-dark-bold d-flex">Frequency:</div>
            <div className="SA-Main-Text-Color d-flex flex-wrap">{patient.frequency}/Week</div>
          </div>
          <div className="d-flex flex-column mb-2">
            <div className="SA-label-dark-bold d-flex">Address:</div>
            <div className="SA-Main-Text-Color d-flex flex-wrap">{patient.address}/Week</div>
          </div>
          <div className="d-flex flex-column mb-2">
            <div className="SA-label-dark-bold d-flex mb-2">Cannot be Seen:</div>
            <div className="d-flex flex-wrap align-items-center">
              <div className="d-flex flex-wrap">
                {patient.noSeeDays && Object.entries(patient.noSeeDays).map(([propertyName, propertyValue]) =>
                    propertyValue ? (
                        <div className="d-flex me-1 mb-1 badge" id="customBadge" key={propertyName}>
                            {propertyName}
                        </div>
                    ) : null
                )}
              </div>
            </div>
          </div>
        </div>}
      </Popover.Body>
    </Popover>
  );

  if(homeStatus === 'loading') {
    return <div><Loading /></div>;
  }

  if(homeStatus === 'error') {
    return <div>Error Loading Patients...</div>;
  }

  if(!homes) {
    return <div>No Patients saved</div>;
  }
 
  return (
    <div>
      <div className="d-flex w-100 flex-wrap">
          {remainingPatients.map((patient) => 
            (   
              <div key={patient._id} className="d-flex justify-content-start align-items-start m-1">
                <OverlayTrigger trigger="hover" placement="bottom" overlay={popover}>
                  <button 
                    draggable 
                    onMouseEnter={() => handleHover(patient)}
                    onDragStart={() => handleDragStart(`${patient.firstName} ${patient.lastName}`, patient.address, patient.coordinates, patient.additional ? null : undefined, patient.additional, null)} 
                    className={`${styles.patientCard} d-flex justify-content-start align-items-center`}
                  >
                    {patient.firstName} {patient.lastName}
                  </button>
                </OverlayTrigger>
              </div>
            ) 
          )}
      </div>
    </div>
    
  )
}

export default PatientScheduleList

  