import React, { useRef } from 'react';
import { useState } from "react";
import { useAlert } from '../context/alert-context';
import emailjs from '@emailjs/browser';
import { Form, Button } from 'react-bootstrap';

const Waitlist = ({handleClose}) => {
  const form = useRef();
  const { showAlert } = useAlert();
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_note: ''
  });

  const handleInputChange = (event) => {
    const {name, value} = event.target;

    setFormData({
      ...formData,
      [name]: value
    });
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_7h6l1j2', 'template_udwfu8r', form.current, 'wLYZCwyNGuTe-MHOZ')
      .then((result) => {
        window.alert("We're excited to give you more time! Thank you!");
        handleClose();

      }, (error) => {
          showAlert(`Whoops, please try again.`, 'Oh, Snap!', 'danger');
          console.log(error.text);
      });
  };

  const isButtonDisabled = formData.user_name.trim() === '' || formData.user_email.trim() === '';

  return (
    <div className="d-flex flex-column">
      <Form ref={form} onSubmit={sendEmail}>
        <Form.Label className='SA-label-dark-bold ms-3'>Name</Form.Label>
        <Form.Control
          type="text"
          className="SA-form-input mt-1 mb-4"
          placeholder='Jane Doe'
          id="user_name"
          name="user_name"
          value={formData.user_name}
          onChange={(event) => handleInputChange(event)}
        />
        <Form.Label className='SA-label-dark-bold ms-3'>Email</Form.Label>
        <Form.Control
          type="text"
          className="SA-form-input mt-1 mb-4"
          placeholder='example@gmail.com'
          id="user_email"
          name="user_email"
          value={formData.user_email}
          onChange={(event) => handleInputChange(event)}
        />
        <Form.Label className='SA-label-dark-bold ms-3'>Feedback/Feature Request</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          type="text"
          className="SA-form-textArea mt-1 mb-4"
          placeholder='Any questions, comments, concerns?'
          id="user_note"
          name="user_note"
          value={formData.user_note}
          onChange={(event) => handleInputChange(event)}
        />
        <div className="d-flex justify-content-end">
          <Button className='SA-Preset-Btn' disabled={isButtonDisabled} type="submit">
            Join
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default Waitlist;