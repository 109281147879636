import { Offcanvas } from 'react-bootstrap';
import styles from "./Modal.module.css";

const SlideModalBottom = ({ title, component, show, handleClose }) => {
  return (
    <Offcanvas className={`${styles.offCanvas} ${styles.bottomModal}`} show={show} onHide={handleClose} placement='bottom' name='bottom'>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title id={styles.title}>{title}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {component}
      </Offcanvas.Body>
    </Offcanvas> 
  );
}

export default SlideModalBottom;