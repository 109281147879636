import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import usePatientRequests from '../../hooks/patient-requests';
import { useAlert } from '../../context/alert-context.js';
import {UserContext, AccessTokenContext} from '../../context/context';
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../pop-ups/loading";
import ErrorScreen from '../../components/Error-Screen';
import SlideModal from '../../static/modals/Slide_Modal';
import SlideModalBottom from '../../static/modals/Slide_Modal_Bottom';
import EditPatient from '../../Modal_Components/Edit_Patient';
import CreateNote from '../../Modal_Components/Create_Note';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import { Form, Badge, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faEllipsis, faPen, faFolderMinus } from '@fortawesome/free-solid-svg-icons';
import styles from "./Patient_Info.module.css";
import EditNote from '../../Modal_Components/Edit_Note';
import ConfirmModal from '../../static/modals/Confirm_Modal.js';

const PatientInfo = ({isOpen}) => {  
  const user = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);
  const { isLoading } = useAuth0();

  const {viewPatient, updatePatient, removePatient} = usePatientRequests();
	const { showAlert } = useAlert();
  const { id } = useParams();
  const { data: patient, status, refetch } = useQuery('patient',
    () => viewPatient(user.authId, id, accessToken)
  );

  const [patientData, setPatientData] = useState(undefined);
  const [hasError, setHasError] = useState(false);
  const [hoveredId, setHoveredId] = useState(null);
  const [showEditPatient, setShowEditPatient] = useState(false);
  const [showBottomNote, setShowBottomNote] = useState(false);
  const [showBottomEditNote, setShowBottomEditNote] = useState(false);
	const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [noteToEdit, setNoteToEdit] = useState({editId: '', patientData: '' });
	const gray = 'var(--subtitle)';

  useEffect(() => {
    if(patient) {
      setPatientData(patient.message);
    } 
  },[patient]);

  useEffect(() => {
    handleRefetch();
  }, [isOpen]);

  const handleEditInfo = () => setShowEditPatient(true);
  const handleNote = () => setShowBottomNote(true);
  const showEditNote = () => setShowBottomEditNote(true);

  const handleClose = () => {
    setShowEditPatient(false);
    setShowBottomNote(false);
    setShowBottomEditNote(false);
		setShowArchiveModal(false);
    handleRefetch();
  }
  
  const handleDeletePatientModal = () => {
    const deleteProps = {
      patientData: patientData,
      patientId: id,
    }
		setShowArchiveModal(true);
  }

	const handleArchivePatient = async () => {
		const response = await removePatient(patientData._id, user.authId, accessToken);
		if(response.status !== 204) {
      showAlert(`ERROR: ${response.message}`, 'Oh, Snap!', 'danger');
    } else {
			showAlert(`Your Patient was successfully archived.`, 'Success!', 'success');
			window.location.href = "/manage";
		}
	}

  const handleEditNote = (noteId) => {
    const editProps = {
      patientData: patientData,
      editId: noteId
    }
    setNoteToEdit(editProps);

    showEditNote();
  }

  const handleActive = () => {
    setPatientData((prev) => {
      const updatedData = { ...prev, active: !prev.active };
      
      setTimeout(() => {
        handleSave(updatedData);
      }, 500);
  
      return updatedData;
    });

  }

  const handleSave = async (data) => {
    const response = await updatePatient(data, accessToken);
    if(response.status !== 201) {
      showAlert(`ERROR: ${response.message}`, 'Oh, Snap!', 'danger');
    }
  }

  const handleNoteRemoval = (noteId) => {
    setPatientData((prevData) => ({
      ...prevData,
      notes: prevData.notes.filter((note) => note.noteId !== noteId)
    }));
  }

  const handleRefetch = async () => {
    const { data } = await refetch();
    if(data.status !== 200) {
      setHasError(true);
    }
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      This change cannot be undone.
    </Tooltip>
  );

  const renderNoteToolTip = (props) => (
    <Tooltip id={styles.buttonTooltip} {...props}>
      Add a note!
    </Tooltip>
  );

  const addButton = () => {
    return (
      <Button className={`${styles.circularButton} d-flex align-items-center justify-content-center`} onClick={handleNote}>
        +
      </Button>

    );
  }

  if(isLoading) {
    return <div><Loading /></div>
  }

  if(!patientData) {
    return <div><Loading /></div>
  }

  if(hasError) {
    return (
      <div className="row">
        <div className="col">
          <ErrorScreen />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.custContainer}>
      <Row className='d-flex justify-content-center align-items-center w-100'>
        <Col xs={12} md={12} lg={4} className={`${styles.patientBorder} py-3 mt-4`}>
          <Row>
            <Col className='d-flex justify-content-end align-items-start'>
							<Dropdown className={styles.dropdown}>
								<Dropdown.Toggle variant="none" id="dropdown-basic" className={`${styles.dropdownToggle} d-flex align-items-center`}>
									<FontAwesomeIcon color={gray} icon={faEllipsis} style={{ fontSize: '30px' }} />
								</Dropdown.Toggle>

								<Dropdown.Menu className={styles.dropdownMenu}>
									<Dropdown.Item className={`${styles.dropdownItem} d-flex align-items-center justify-content-between`} onClick={handleEditInfo}>Edit <FontAwesomeIcon color={'var(--mainTextDark)'} icon={faPen} style={{ fontSize: '15px' }} /></Dropdown.Item>
									<Dropdown.Item className={`${styles.dropdownItem} d-flex align-items-center justify-content-between`}onClick={handleDeletePatientModal}>Archive Patient <FontAwesomeIcon color={'var(--mainTextDark)'} icon={faFolderMinus} style={{ fontSize: '15px' }} /></Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
            </Col>
          </Row>

          <Row className={`${styles.separator} py-2 mb-3`}>
            <Col>
              <div className={`${styles.patientTitle} d-flex justify-content-center align-items-center`}>
                {patientData.firstName} {patientData.lastName}
              </div>
            </Col>
          </Row>

          <Row className={`${styles.separator} py-2 mb-3`}>
            <Col md={6} className='my-2'>
              <Form.Label className='SA-label-dark-bold'>Primary: </Form.Label>
            </Col>
            <Col md={6} className='my-2'>
              <div className='SA-Main-Text-Color'>
                {patientData.primaryNumber || '--'}
              </div>
            </Col>
            <Col md={6} className='my-2'>
              <Form.Label className='SA-label-dark-bold'>Secondary: </Form.Label>
            </Col>
            <Col md={6} className='my-2'>
              <div className='SA-Main-Text-Color'>
                {patientData.secondaryNumber || '--'}
              </div>
            </Col>
            <Col md={6} className='d-flex align-items-center justify-contents-center'>
              <Form.Label className='SA-label-dark-bold'>Email: </Form.Label>
            </Col>
            <Col md={6}>
              <div className={`${styles.emailArea} text-wrap`}>
                {patientData.email || '--'}
              </div>
            </Col>
          </Row>

          <Row className={`${styles.separator} py-2`}>
            <Col md={6} className='d-flex align-items-center justify-contents-center'>
              <Form.Label className='SA-label-dark-bold'>Address: </Form.Label>
            </Col>
            <Col md={6}>
              <div className='SA-Main-Text-Color'>
                {patientData.address}
              </div>
            </Col>
          </Row>

          <Row className={`py-2`}>
            <Col md={6} className='d-flex align-items-center justify-contents-center'>
              <Form.Label className='SA-label-dark-bold'>Frequency: </Form.Label>
            </Col>
            <Col md={6} className='my-2'>
              <div className='SA-Main-Text-Color'>
                {patientData.frequency} <span className='SA-label-dark-bold'>/Week</span>
              </div>
            </Col>
            <Col md={6} className='d-flex align-items-center justify-contents-center my-2'>
              <Form.Label className='SA-label-dark-bold'>Active: </Form.Label>
            </Col>
            <Col md={6} className='my-2'>
              <div className='SA-Main-Text-Color'>
                <div className="form-check form-switch">
                  <input className="form-check-input npat-input" type="checkbox" role="switch" id="active" onChange={handleActive} checked={patientData.active} />
                </div>
              </div>
            </Col>
            <Col md={6} className='d-flex align-items-center justify-contents-center'>
              <Form.Label className='SA-label-dark-bold'>Cannot Be Seen: </Form.Label>
            </Col>
            <Col md={6}>
              <Row className='mt-2'>
                {Object.entries(patientData.noSeeDays).map(([propertyName, propertyValue]) => (
                  propertyValue && (
                    <Col xs={6} className='mb-2'  key={propertyName}>
                      <Badge className={`${styles.customBadge} d-flex justify-content-center align-items-center text-center`} id={styles.customBadge}>
                        {propertyName}
                      </Badge>
                    </Col>
                  )
                ))}
              </Row>
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={12} lg={7} className={`${styles.patientBorder} ${styles.pInfo} p-3 mt-4`}>
          <Row className={styles.separator}>
            <Col className='d-flex justify-content-start align-items-center'>
              <Form.Label className='SA-label-dark-bold'>Scheduling Notes:</Form.Label>
            </Col>
            <Col className='d-flex justify-content-end align-items-center mb-2'>
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={renderNoteToolTip}
              >
                {addButton()}
              </OverlayTrigger>
            </Col>
          </Row>
          <div className='d-flex justify-content-center flex-column align-items-center'>
            {patientData.notes.map((pNote) => (
              <Row key={pNote.noteId} className={`${styles.noteContainer} ${hoveredId === pNote.noteId ? styles.highlightContainer : ''} p-2 my-4`}>
                <Col className="note-cont">
                  <Row>
                    <Col className='d-flex justify-content-end'>
                      <div className="note-time">{pNote.noteDate}</div>
                    </Col>
                  </Row>

                  <Row className="py-2">
                    <Col>
                      <div className={styles.noteContent}>{pNote.note}</div>
                    </Col>
                  </Row> 

                  <Row className="note-bottom">
                    <Col className='d-flex justify-content-end'>
                      <div className="d-flex">
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <Button 
                            onClick={() => handleNoteRemoval(pNote.noteId)} 
                            onMouseEnter={() => setHoveredId(pNote.noteId)}
                            onMouseLeave={() => setHoveredId(null)} 
                            className='me-2 SA-Transparent-Btn'>
                              Delete
                          </Button>
                        </OverlayTrigger>
                        <Button 
                          onMouseEnter={() => setHoveredId(pNote.noteId)}
                          onMouseLeave={() => setHoveredId(null)} 
                          onClick={() => handleEditNote(pNote.noteId)} 
                          className='SA-Small-Btn'>
                            Edit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
          </div>
          
        </Col>
      </Row>

      <SlideModal 
        title="Patient Info" 
        component={<EditPatient patient={patientData} accessToken={accessToken} userId={user.authId} handleClose={handleClose} />} 
        show={showEditPatient} 
        handleClose={handleClose}
      />
      <SlideModalBottom 
        title="Patient Notes" 
        component={<CreateNote patientData={patientData} accessToken={accessToken} userId={user.authId} closeModal={handleClose} />} 
        show={showBottomNote} 
        handleClose={handleClose}
      />
      <SlideModalBottom 
        title="Edit Your Note" 
        component={<EditNote modalProps={noteToEdit} accessToken={accessToken} closeModal={handleClose} />} 
        show={showBottomEditNote} 
        handleClose={handleClose}
      />
			<ConfirmModal
				title="Archive Patient?"
				body="This action cannot be undone."
				show={showArchiveModal}
				handleConfirm={handleArchivePatient}
				handleClose={handleClose}
			/>
    </div>
  );
}

export default PatientInfo;
