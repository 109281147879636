import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalComponent = ({ title, component, show, handleClose, handleAction, actionTitle, buttonClass }) => {

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{component}</Modal.Body>
        <Modal.Footer>
          <Button className="SA-Transparent-Btn" onClick={handleClose}>
            Cancel
          </Button>
          <Button className={`${buttonClass}`} onClick={handleAction}>
            {actionTitle}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalComponent;