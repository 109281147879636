import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "react-big-calendar/lib/css/react-big-calendar.css"
import { AlertProvider } from './context/alert-context';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Auth0Provider
        domain="dev-uhybzq8zwt4f7tgf.us.auth0.com"
        clientId="hyy5wefCkgGBTb8fcqPmEiqRZohiBfZh"
        authorizationParams={{
            redirect_uri: window.location.origin,
            audience: "https://www.scheduleaid-api.com",
            scope: "read:user write:user"
          }} 
    >
        <QueryClientProvider client={queryClient}>
          <AlertProvider>
            <App />
          </AlertProvider>
        </QueryClientProvider>
    </Auth0Provider>,
);


reportWebVitals();
