import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import Waitlist from '../../Modal_Components/Waitlist';
import FloatingModal from '../modals/Floating_Modal';

import styles from './Nav.module.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';

const IntroNav = ({ location }) => {
  const { loginWithRedirect } = useAuth0();
  const [activeLink, setActiveLink] = useState("#home");
  const [waitlist, setwaitlist] = useState(false);

  const handleSelect = (eventKey) => {
    setActiveLink(eventKey);
  };

  const handleClose = () => {
    setwaitlist(false);
  }

  const handleWaitlist = () => {
    setwaitlist(true);
  }

  const handleSignup = () => {
    loginWithRedirect({ action: 'signup' })
  };

  const joinButton = () => {
    return <Button className={styles.joinBtn} onClick={() => handleSignup()}>Join</Button>
  }

  const joinWaitlist = () => {
    return (
      <div className={`pt-1 d-flex justify-content-left align-items-left`}>
        <Button className='SA-Preset-Btn' onClick={() => handleWaitlist()}>Join Waitlist</Button>
      </div>
    );
  }

  return (
    <Navbar collapseOnSelect expand="lg" className={styles.navbar}>
      <Container>
        <Navbar.Brand className={styles.custNavbarBrand} href="#home">MySchedulePal</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" activeKey={activeLink} onSelect={handleSelect}>
            <Nav.Link 
              href="#features" 
              eventKey="#features" 
              className={`${styles.navLink} ${activeLink === "#features" ? styles.navLinkActive : ""}`}
            >
              Features
            </Nav.Link>
            <Nav.Link 
              href="#steps" 
              eventKey="#steps" 
              className={`${styles.navLink} ${activeLink === "#steps" ? styles.navLinkActive : ""}`}
            >
              How it Works
            </Nav.Link>
            <Nav.Link 
              href="#hippa" 
              eventKey="#hippa" 
              className={`${styles.navLink} ${activeLink === "#hippa" ? styles.navLinkActive : ""}`}
            >
              HIPPA
            </Nav.Link>
            <Nav.Link 
              href="#pricing" 
              eventKey="#pricing" 
              className={`${styles.navLink} ${activeLink === "#pricing" ? styles.navLinkActive : ""}`}
            >
              Pricing
            </Nav.Link>
            <Nav.Link 
              href="#about" 
              eventKey="#about" 
              className={`${styles.navLink} ${activeLink === "#about" ? styles.navLinkActive : ""}`}
            >
              About Us
            </Nav.Link>            
          </Nav>
          <Nav activeKey={activeLink} onSelect={handleSelect}>
            {joinWaitlist()}
          </Nav>
        </Navbar.Collapse>
      </Container>
      <FloatingModal 
        title="Join 130+ Therapists" 
        component={<Waitlist handleClose={handleClose}/>} 
        show={waitlist} 
        handleClose={handleClose}
      />
    </Navbar>
  );
}

export default IntroNav;

