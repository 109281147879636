import { useEffect, useState } from "react";
import { AddressAutofill, useConfirmAddress  } from '@mapbox/search-js-react';
import { useAlert } from "../context/alert-context";

import usePatientRequests from "../hooks/patient-requests";
import Loading from "../pop-ups/loading";

import { Form, Badge, CloseButton, Button, Row, Col, Container} from 'react-bootstrap';
import styles from "./Modal_Components.module.css";

const EditPatient = ({patient, accessToken, userId, handleClose}) => {
  const maps_access_Token = process.env.REACT_APP_MAPS_API_KEY;

  const [patientData, setPatientData] = useState(patient);
  const [isLoading, setIsLoading] = useState(false);

  // const { formRef, showConfirm } = useConfirmAddress({
  //   accessToken: maps_access_Token,
  // });
  const { updatePatient } = usePatientRequests();
  const { showAlert, hideAlert } = useAlert();

  const handleInputChange = (event) => {
    const {name, value} = event.target;

    setPatientData({
        ...patientData,
        [name]: value
    });
  }

  const handleNoSeeDays = (value) => {
    setPatientData((prev) => ({
      ...prev,
      noSeeDays: {
        ...prev.noSeeDays,
        [value]: !prev.noSeeDays[value]
      }
    }));
  }

  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    if(name === ' address-search') {
      setPatientData(prev => ({
        ...prev,
        addressObject: {
            ...prev.addressObject,
            addressLine: value
        }
      }));
    } else {
        setPatientData(prev => ({
        ...prev,
        addressObject: {
            ...prev.addressObject,
            [name]: value
        }
      }));
    } 
  } 

  const checkForAddress = () => {
    let isAddress;
    for (const key in patientData.addressObject) {
      if (Object.hasOwnProperty.call(patientData.addressObject, key)) {
        const element = patientData.addressObject[key];

        if(element === '' && key !== 'apartment') {
          isAddress = false;
          break;
        } else {
          isAddress = true;
        }
      }
    }
    return isAddress;
  }

  const handleSave = async () => {
    // const result = await showConfirm();

    const response = await updatePatient(patientData, accessToken);
    if(response.status === 201) {
      setIsLoading(true);
      showAlert('The updated patient info was saved successfully.', 'Success!',  'success');
      setTimeout(() => {
        handleClose()
      }, 800);

    } else {
      showAlert(`ERROR: ${response.message}`, 'Oh, Snap!', 'danger');;
    }
  }
  
  if(isLoading) {
    return (
      <Container fluid className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <Loading />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col md={6}>
          <Form.Label className='SA-label'>First Name</Form.Label>
          <Form.Control
            type="text"
            className="SA-form-input my-2"
            placeholder='e.g. Jane'
            id="firstName"
            name="firstName"
            value={patientData.firstName}
            onChange={(event) => handleInputChange(event)}
          />
        </Col>
        <Col md={6}>
          <Form.Label className='SA-label'>Last Name</Form.Label>
          <Form.Control
            type="text"
            className="SA-form-input my-2"
            placeholder='e.g. Doe'
            id="lastName"
            name="lastName"
            value={patientData.lastName}
            onChange={(event) => handleInputChange(event)}
          />
        </Col>
      </Row>
      <Row className='py-3'>
        <Col>
          <Form.Label className='SA-label'>Address</Form.Label>
          {/* <Form ref={formRef}> */}
          <Form>
            <Row>
              <Col md={9}>
                <AddressAutofill accessToken={maps_access_Token}>
                  <Form.Control
                      type="text"
                      className="SA-form-input my-2"
                      placeholder="Address" 
                      autoComplete="address-line1"
                      onChange={handleAddressChange}
                      value={patientData.addressObject.addressLine}
                  />
                </AddressAutofill>
              </Col>
              <Col md={3}>
                <Form.Control
                  type="text"
                  className="SA-form-input my-2"
                  name="apartment" placeholder="Apt"
                  autoComplete="address-line2"
                  onChange={handleAddressChange}
                  value={patientData.addressObject.apartment}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Control
                  type="text"
                  className="SA-form-input my-2"
                  name="city" placeholder="City"
                  autoComplete="address-level2"
                  onChange={handleAddressChange}
                  value={patientData.addressObject.city}
                />
              </Col>
              <Col md={6}>
                <Form.Control
                  type="text"
                  className="SA-form-input my-2"
                  name="state" placeholder="State"
                  autoComplete="address-level1"
                  onChange={handleAddressChange}
                  value={patientData.addressObject.state}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Control
                  type="text"
                  className="SA-form-input my-2"
                  name="country" placeholder="Country" 
                  autoComplete="country"
                  onChange={handleAddressChange}
                  value={patientData.addressObject.country}
                />
              </Col>
              <Col md={6}>
                <Form.Control
                  type="text"
                  className="SA-form-input my-2"
                  name="postcode" placeholder="Postcode"
                  autoComplete="postal-code"
                  onChange={handleAddressChange}
                  value={patientData.addressObject.postcode}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row className='py-3'>
        <Col md={6}>
          <Form.Label className='SA-label'>Primary Number</Form.Label>
          <Form.Control
            type="tel"
            className="SA-form-input my-2"
            placeholder='e.g. 480-123-456'
            id="primaryNumber"
            name="primaryNumber"
            value={patientData.primaryNumber}
            onChange={(event) => handleInputChange(event)}
          />
        </Col>
        <Col md={6}>
          <Form.Label className='SA-label'>Secondary Number</Form.Label>
          <Form.Control
            type="tel"
            className="SA-form-input my-2"
            placeholder='e.g. 480-123-456'
            id="secondaryNumber"
            name="secondaryNumber"
            value={patientData.secondaryNumber}
            onChange={(event) => handleInputChange(event)}
          />
        </Col>
      </Row>
      <Row className='py-3'>
        <Col>
          <Form.Label className='SA-label'>Email</Form.Label>
          <Form.Control
            className="SA-form-input my-2"
            placeholder='e.g. janedoe@example.com'
            id="email"
            name="email"
            value={patientData.email}
            onChange={(event) => handleInputChange(event)}
          />
        </Col>
      </Row>
      <Row className='py-3'>
        <Col md={4}>
          <Form.Label className='SA-label'>Cannot Be Seen:</Form.Label>
          <Form.Select className="SA-form-input" name="noseeDays" id="noSeeDays" onChange={(event) => handleNoSeeDays(event.target.value)}>
            <option disabled defaultValue={"Select Days"} value="">Select Days</option>
            <option className={`${patientData.noSeeDays.sunday ? 'd-none' : null}`} value="sunday">
              Sunday
            </option>
            <option className={`${patientData.noSeeDays.monday ? 'd-none' : null}`} value="monday">
              Monday
            </option>
            <option className={`${patientData.noSeeDays.tuesday ? 'd-none' : null}`} value="tuesday">
              Tuesday
            </option>
            <option className={`${patientData.noSeeDays.wednesday ? 'd-none' : null}`} value="wednesday">
              Wednesday
            </option>
            <option className={`${patientData.noSeeDays.thursday ? 'd-none' : null}`} value="thursday">
              Thursday
            </option>
            <option className={`${patientData.noSeeDays.friday ? 'd-none' : null}`} value="friday">
              Friday
            </option>
            <option className={`${patientData.noSeeDays.saturday ? 'd-none' : null}`} value="saturday">
              Saturday
            </option>
          </Form.Select>
        </Col>
        <Col md={8}>
          <Row>
            {
              Object.entries(patientData.noSeeDays).map(([propertyName, propertyValue]) => (
                propertyValue && (
                <Col className='m-2' key={propertyName}>
                  <Badge className={`${styles.customBadge} d-flex justify-content-start align-items-center`} id={styles.customBadge}>
                    <CloseButton className={`${styles.xBtn} me-2`} onClick={() => handleNoSeeDays(propertyName)} /> 
                    {propertyName}
                  </Badge>
                </Col>
              )))
            }
          </Row>
        </Col> 
      </Row>
      <Row className='py-4'>
        <Col className='d-flex justify-content-center align-items-center'>
          <Button disabled={checkForAddress() === false || patientData.firstName === '' || patientData.lastName === ''} onClick={handleSave} className="SA-Primary-Btn">Save</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default EditPatient;