import React from "react";
import { useEffect, useState } from "react";

import Loading from "../pop-ups/loading";

import useComparisonRequests from "../hooks/comparison-requests";
import useScheduleRequests from "../hooks/schedule-requests";
import { useAlert } from "../context/alert-context";

const EventModal = ({modalProps}) => {

  const { abbrevationFix } = useComparisonRequests();
  const { deleteEvent } = useScheduleRequests();
  const { showAlert } = useAlert();

  const [patient, setPatient] = useState(undefined);
  const [patientSeeDays, setPatientSeeDays] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    const clientAddress = abbrevationFix(modalProps.client.address);

    const patientData = modalProps.patients.find((patient) => {
      const patAddress = abbrevationFix(patient.address);
      return patAddress === clientAddress && `${patient.firstName} ${patient.lastName}` === modalProps.client.title;
    });

    setPatient(patientData);

  }, [modalProps.client, modalProps.groups, modalProps.patients]);

  useEffect(() => {
    if (patient && patient.noSeeDays) {
      const entries = Object.entries(patient.noSeeDays);
      for (let i = 0; i < entries.length; i++) {
        const [propertyName, propertyValue] = entries[i];
        if (propertyValue) {
          setPatientSeeDays(true);
          break;
        }
      }
    }
  }, [patient]);

  if(isLoading) {
    return <Loading />
  }

  return (
    <div className="container">
      <div className="row my-1">
        <div className="col">
          <div className="d-flex justify-content-end">{modalProps.client.start}</div>
        </div>
      </div>

      <div className="row my-1">
        <div className="col">
          <div className="d-flex align-items-center justify-content-start ">
            <h6>{modalProps.client.title}</h6>
          </div>
        </div>
      </div>

      {patient && patientSeeDays && patient.noSeeDays ? (
        <div className="row my-3">
          <div className="col-4">
            <div className="SA-label-dark-bold">Cannot be Seen:</div>
          </div>
          <div className="col d-flex align-items-center">
            <div className="row">
                {Object.entries(patient.noSeeDays).map(([propertyName, propertyValue]) =>
                propertyValue ? (
                    <div className="col-6 d-flex me-1 badge" id="customBadge" key={propertyName}>
                    {propertyName}
                    </div>
                ) : null
                )}
            </div>
          </div>
        </div>
      ) : null}

      {patient ? (
        <div>
          <div className="row my-3">
            <div className="col-4">
              <div className="SA-label-dark-bold">Primary Number:</div>
            </div>
            <div className="col">
              <div className="SA-Main-Text-Color">{patient.primaryNumber || 'NA'}</div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-4">
              <div className="SA-label-dark-bold">Frequency:</div>
            </div>
            <div className="col">
              <div className="SA-Main-Text-Color">{patient.frequency}/week</div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-4">
              <div className="SA-label-dark-bold">Address:</div>
            </div>
            <div className="col">
              <div className="SA-Main-Text-Color">{patient.address}</div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default EventModal;
