import React, { useRef } from "react";
import Slider from "react-slick";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

function SmallCarousel() {
  let sliderRef = useRef(null);
  const heartColor = 'red';

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <div className="slider-container">
      <Slider ref={slider => (sliderRef = slider)} {...settings}>
        <div>
          <div>Occupational Therapists <FontAwesomeIcon color={heartColor} icon={faHeart} /></div>
        </div>
        <div>
          <div>COTAs <FontAwesomeIcon color={heartColor} icon={faHeart} /></div>
        </div>
        <div>
          <div>Speech Language Pathologists <FontAwesomeIcon color={heartColor} icon={faHeart} /></div>
        </div>
        <div>
          <div>SLPAs <FontAwesomeIcon color={heartColor} icon={faHeart} /></div>
        </div>
        <div>
          <div>Physical Therapists <FontAwesomeIcon color={heartColor} icon={faHeart} /></div>
        </div>
        <div>
          <div>Nurses <FontAwesomeIcon color={heartColor} icon={faHeart} /></div>
        </div>
      </Slider>
    </div>
  );
}
export default SmallCarousel;

