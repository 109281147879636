import React from "react";
import YouTube from 'react-youtube';
import styles from "./Modal_Components.module.css"

const VideoPlayer = ({link}) => {
  const videoOptions = {
    playerVars: {
      autoplay: 1,
    },
  };

  return(
    <div className={styles.videoContainer}>
      <YouTube videoId={link} opts={videoOptions} />
    </div>
  );
}

export default VideoPlayer;