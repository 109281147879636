import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import {UserContext, AccessTokenContext} from '../../context/context';
import usePatientRequests from '../../hooks/patient-requests';

import Loading from '../../pop-ups/loading';
import ErrorScreen from '../../components/Error-Screen';
import SlideModal from '../../static/modals/Slide_Modal';
import CreatePatient from '../../Modal_Components/Create_Patient';

import { Form, Button, InputGroup, Row, Col, Container, Stack } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from "./Patient_List.module.css";

function PatientList({openModal, isOpen}) {
  const queryClient = useQueryClient();
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const isXSmallScreen = useMediaQuery({ maxWidth: 576 });
  const SASecondary = 'var(--mainText)';

  const { isLoading } = useAuth0();
  const user = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);

  const { getPatients } = usePatientRequests();
  const { data: patients, refetch } = useQuery('patients', 
    () => getPatients(user.authId, accessToken)
  );

  const [filteredPatients, setFilteredPatients] = useState([]);
  const [filter, setFilter] = useState(null);
  const [searchFilter, setSearchFilter] = useState('');
  const [hasError, setHasError] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    handleRefetch();
  }, [isOpen]);

  useEffect(() => {
    if (patients !== undefined && Array.isArray(patients.message)) {
      setFilteredPatients(patients.message.reverse());
    }
  }, [patients]);

  const handleClose = () => {
    setShow(false);
    handleRefetch();
  }
  const handleShowAddPatient = () => setShow(true);
  
  const filterSelection = (filterEvent) => {
    setFilter(filterEvent);

    if(filterEvent === 'A-Z') {
      const sortAZ = patients.message.sort((a, b) => a.lastName.localeCompare(b.lastName));
      sortAZ.length > 0 ? setFilteredPatients(sortAZ) : window.alert('Add Patients!');
    } else if(filterEvent === 'Z-A')  {
      const sortZA = patients.message.sort((a, b) => b.lastName.localeCompare(a.lastName));
      sortZA.length > 0 ? setFilteredPatients(sortZA) : window.alert('Add Patients!');
    } else if(filterEvent === 'Active') {
      const filterActive = patients.message.filter((patient) => patient.active);
      filterActive.length > 0 ? setFilteredPatients(filterActive) : window.alert('You have no Active patients');
    } else if(filterEvent === 'Inactive') {
      const filterInactive = patients.message.filter((patient) => !patient.active);
      filterInactive.length > 0 ? setFilteredPatients(filterInactive) : window.alert('You have no Inactive patients');
    } else if(filterEvent === 'All') {
      setFilteredPatients(patients.message);
    }
  }

  const handleSearchName = (event) => { 
    setSearchFilter(event);
  }

  const searchName = () => {
    const isName = patients.message.filter((patient) => patient.firstName.toUpperCase() === searchFilter.toUpperCase() || patient.lastName.toUpperCase() === searchFilter.toUpperCase());

    isName.length > 0 ? setFilteredPatients(isName) : window.alert(`Sorry, it looks like there is no one by the name of ${searchFilter} in your patient list.`);

    setFilter(null);
  }

  const handleRefetch = async () => {
    const { data, error, failureCount, isFetching } = await refetch();
    if(data.error || data.status !== 200) {
      setHasError(true);
    } else if(data) {
      setHasError(false);
    }
  }

  const filterSearch = () => {
    return (
      <div className="d-flex justify-content-around align-items-center">
        <div className={`${isSmallScreen ? 'd-flex justify-content-start align-items-center py-3' : 'd-flex justify-content-center align-items-center'}`}>
          <a className="my-anchor-element">{addButton()}</a>
          <Tooltip className={styles.tooltip} anchorSelect=".my-anchor-element" place="right">
            Add a Patient!
          </Tooltip>
        </div>
        <div className={`${isSmallScreen ? 'd-flex justify-content-start align-items-center py-3' : 'd-flex justify-content-center align-items-center'} w-25`}>
          <Form.Select 
            name="filter" 
            id="filter" 
            className="SA-form-input" 
            onChange={(event) => filterSelection(event.target.value)}
            value={filter || ''}
          >
            <option disabled value="">Filter</option>
            <option value="All">All</option>
            <option value="A-Z">A-Z</option>
            <option value="Z-A">Z-A</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </Form.Select>
        </div>
        <div className="d-flex justify-content-center align-items-center w-25">
          <InputGroup className="search-group">
            <Form.Control
              type="text"
              className="SA-form-input"
              placeholder="Search By Name"
              value={searchFilter}
              onChange={(e) => handleSearchName(e.target.value)}
            />
            <Button onClick={searchName} className={`${styles.searchBtn} ms-1`} type="button">Search</Button>
          </InputGroup>
        </div>
      </div>
    );
  }
  
  const addButton = () => {
    return (
      <Button className={`${styles.circularButton} d-flex align-items-center justify-content-center`}  onClick={handleShowAddPatient}>
        <FontAwesomeIcon icon={faPlus} className={styles.plus} />
      </Button>

    );
  }

  const header = () => {
    return (
      <Container className={`${styles.headerContainer} d-flex justify-content-start align-items-center`}>
        <Row className='w-100 py-3'>
          <Col xs={6} sm={5} md={4} className='d-flex align-items-center'>Name</Col>
          <Col xs={6} sm={4} md={3} className='d-flex align-items-center'>Number</Col>
          <Col xs={5} className={isSmallScreen ?'d-none' : 'd-flex align-items-center'}>Address</Col>
        </Row>
      </Container>
    );
  }

  const buildPatientCard = (patient) => {
    return (
      <Link className={styles.link} to={`/manage/${patient._id}`}>
        <Container className={`${styles.patientCardContainer} d-flex justify-content-start align-items-center`}>
          <Row className='w-100'>
            <Col xs={6} sm={5} md={4} className={`d-flex align-items-center ${styles.text}`}>
              <div className={styles.overflow}>{patient.firstName} {patient.lastName}</div>
            </Col>
            <Col xs={6} sm={4} md={3} className={`d-flex align-items-center ${styles.text}`}>{patient.primaryNumber}</Col>
            <Col md={4} className={isSmallScreen ? 'd-none' : `d-flex align-items-center`}>
              <div className={`${styles.overflow} ${styles.text}`}>{patient.address}</div>
            </Col>
            <Col xs={3} md={1} className={isXSmallScreen ? 'd-none' : 'd-flex align-items-center justify-content-end'}> <FontAwesomeIcon icon={faChevronRight} color={SASecondary} className={styles.forward} /> </Col>
          </Row>
        </Container>
      </Link>
    );
  }

  if(isLoading) {
    return (
      <Container className='d-flex justify-content-center align-items-center'>
        <Loading />
      </Container>
    );
  }

  if(hasError) {
    return (
      <Container className='d-flex justify-content-center align-items-center'>
        <ErrorScreen />
      </Container>
    );
  }

  return (
    <div className={styles.background}>
      <Container>
        <Stack className={`${styles.customContainer} py-5 mt-5`}>
          <div>{filterSearch()}</div>
          <div>{header()}</div>
          {Array.isArray(filteredPatients) && filteredPatients.length > 0 ? (
            filteredPatients.map((patient) => (
              <div className='py-2 w-100' key={patient._id}>
                {buildPatientCard(patient)}
              </div>
            
            ))
          ) : (
            <p>No patients available</p>
          )}
        </Stack>

        <SlideModal 
          title="Create a New Patient" 
          component={<CreatePatient userId={user.authId} accessToken={accessToken} handleClose={() => handleClose()}  />} 
          show={show} 
          handleClose={handleClose}
        />
        
      </Container>
    </div>
  );
}

export default PatientList;



 