import Modal from 'react-bootstrap/Modal';

import styles from './Modal.module.css';

const FloatingModal = ({ title, component, handleClose, show }) => {

  return (
    <>
      <Modal className={styles.floatingModal} size='lg' show={show} onHide={handleClose}>
        <Modal.Header className={styles.floatingModalHeader} closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.floatingModalBody}>{component}</Modal.Body>
        <Modal.Footer className={styles.floatingModalFooter}></Modal.Footer>
      </Modal>
    </>
  );
}

export default FloatingModal;