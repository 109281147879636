function useUserRequests () {
  const url = 'http://localhost:8080';

  const returnObject = (status, message) => {
    return {
      status: status,
      message: message
    }
  };

  const getUser = async (userId, accessToken) => {
    try {
      const response = await fetch(`${url}/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      const userData = await response.json();

      return userData;
      
    } catch (error) {
        console.error('Error:', error);
        return returnObject(500, `"${error.message}." \nLooks like there was an internal error or the right information was not submitted please try again.`);
    }
  };

  const addUser = async (newUser, accessToken) => {
    try {
      const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}`},
        body: JSON.stringify(newUser)
      };

      const response = await fetch(`${url}/user`, options);
      const userAdded = await response.json();

      return userAdded;
    } catch (error) {
        console.error('Error:', error);

        const errorResponse = {
          error: error.message,
          status: error.status
        };

        return errorResponse;
    }
  };

  const updateUserWorkCustomization = async (userId, accessToken, userInfo) => {
    try {
      const options = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}`},
        body: JSON.stringify(userInfo)
      };

      const response = await fetch(`${url}/user/${userId}/workcustomization`, options);

      const userUpdated = await response.json();

      return userUpdated;
    } catch (error) {
      return returnObject(500, error);
    }
  };

  return {
    getUser,
    addUser,
    updateUserWorkCustomization
  };
}

export default useUserRequests;


