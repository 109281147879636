import { useEffect, useState } from "react";

import usePatientRequests from "../hooks/patient-requests";
import { useAlert } from "../context/alert-context";

const EditPatientSchedulingModal = ({modalProps, accessToken, closeModal}) => {
  const { updatePatient } = usePatientRequests();
  const { showAlert } = useAlert();

  const [patientData, setPatientData] = useState('');

  useEffect(() => {
    setPatientData(modalProps);
  }, []);

  const handleClose = () => {
    closeModal();
  }

  const handleInputChange = (event) => {
    const {name, value} = event.target;

    setPatientData({
      ...patientData,
      [name]: value
    });
  }

  const handleNoSeeDays = (value) => {
    setPatientData((prev) => ({
      ...prev,
      noSeeDays: {
        ...prev.noSeeDays,
        [value]: !prev.noSeeDays[value]
      }
    }));
  }

  const handleSave = async () => {
    const response = await updatePatient(patientData, accessToken);
    if(response.status === 201) {
      showAlert('The updated patient info was saved successfully.', 'Success!',  'success');
      setTimeout(() => {
        closeModal();
      }, 800);

    } else {
      showAlert(`ERROR: ${response.message}`, 'Oh, Snap!', 'danger');;
    }
  };

  return (
    <div className="page-cont">
      <div className="row my-2">
        <div className="col d-flex justify-content-center flex-column">
          <h4 className='sched-title'>Edit Patient Info</h4>
        </div>
        <div className="col">
          <div className='d-flex justify-content-end'>
            <button type="button" className="btn-close close-all close-all-custom btn-close-create" aria-label="Close"
            onClick={handleClose}></button>
          </div>
        </div>
      </div>

      <div className="row my-2">
        <div className="col-4">
          <div className="contact-label">Frequency:</div>
        </div>
        <div className="col-8">
          <input 
            type="number" 
            className="contact-info extra-input"
            id="frequency"
            name="frequency"
            value={patientData.frequency}
            onChange={(event) => handleInputChange(event)}
          />
        </div>

      </div>

      {
        patientData !== ''
        ? (
            <div className="row my-2">
              <div className="col-4">
                <div className="contact-label nsd-label">Cannot Be Seen:</div>
                <select className="contact-label extra-nsd" name="noseeDays" id="noSeeDays" 
                  onChange={(event) => handleNoSeeDays(event.target.value)}>
                  <option disabled defaultValue={'Select Day'} value="Select Day">Select Day</option>
                  <option className={`${patientData.noSeeDays.sunday ? 'd-none' : null}`} value="sunday">
                    Sunday
                  </option>
                  <option className={`${patientData.noSeeDays.monday ? 'd-none' : null}`} value="monday">
                    Monday
                  </option>
                  <option className={`${patientData.noSeeDays.tuesday ? 'd-none' : null}`} value="tuesday">
                    Tuesday
                  </option>
                  <option className={`${patientData.noSeeDays.wednesday ? 'd-none' : null}`} value="wednesday">
                    Wednesday
                  </option>
                  <option className={`${patientData.noSeeDays.thursday ? 'd-none' : null}`} value="thursday">
                    Thursday
                  </option>
                  <option className={`${patientData.noSeeDays.friday ? 'd-none' : null}`} value="friday">
                    Friday
                  </option>
                  <option className={`${patientData.noSeeDays.saturday ? 'd-none' : null}`} value="saturday">
                    Saturday
                  </option>
                </select>
              </div>
              <div className="col-8">
                <div className="d-flex row nsd-cont my-2">
                  {Object.entries(patientData.noSeeDays).map(([propertyName, propertyValue]) => (
                    propertyValue && (
                      <div className="col-6" key={propertyName}>
                        <span className="badge">
                          {propertyName}
                          <button className="btn-close" onClick={() => handleNoSeeDays(propertyName)}></button>
                        </span>
                      </div>
                    )
                  ))}
                </div>
              </div>
            </div>
          )
        : null
      }

      <div className="row my-2 pe-3">
        <div className="col d-flex justify-content-end align-items-center">
          <button onClick={handleSave} className="add-btn btn save">Save</button>
        </div>
      </div>

    </div>
  );
}

export default EditPatientSchedulingModal;