import { Offcanvas } from 'react-bootstrap';
import styles from "./Modal.module.css";

const SlideModal = ({ title, component, show, handleClose }) => {
  return (
    <Offcanvas className={styles.offCanvas} show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title id={styles.title}>{title}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {component}
      </Offcanvas.Body>
    </Offcanvas> 
  );
}

export default SlideModal;
