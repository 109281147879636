import error_robot from "../assets/images/error_robot.png";

const ErrorScreen = () => {
  return(
    <div>
      <img src={error_robot} alt="error" />
    </div>
  )
}

export default ErrorScreen;