import React, { useEffect } from 'react';
import AOS from 'aos';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'aos/dist/aos.css';

const ScrollAnimation2 = ({widget}) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div data-aos="fade-up">
      {widget}
    </div>
  );
};

export default ScrollAnimation2;