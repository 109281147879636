import React from 'react';

const Divider = () => {
  return (
    <div style={{
      borderLeft: '1px solid #e6be76',
      height: '100%',
      margin: '0 20px'
    }} />
  );
};

export default Divider;

